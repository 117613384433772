import React from 'react';
import { Button, Modal, Select } from 'semantic-ui-react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import reportService from '../../services/reportService';
import Swal from 'sweetalert2';
import { renderMonthElement } from '../form/Form';

const seminaryEvent = [
  {
    text: `Para Siempre`,
    key: `ParaSiempre`,
    value: `ParaSiempre`,
  },
  {
    text: `Proyecto Matrimonio`,
    key: `ProyectoMatrimonio`,
    value: `ProyectoMatrimonio`,
  },
  {
    text: `Relaciones Sanas`,
    key: `RelacionesSanas`,
    value: `RelacionesSanas`,
  },
  {
    text: `Transformacion Empresarial`,
    key: `TransformacionEmpresarial`,
    value: `TransformacionEmpresarial`,
  },
  {
    text: `Escuela de Heroes`,
    key: `EscueladeHeroes`,
    value: `EscueladeHeroes`,
  },
];

const SeminaryReport = () => {
  const [inputs, setInputs] = React.useState({ seminary: seminaryEvent[0].value });
  const [open, setOpen] = React.useState(false);
  const [focused, set_focused] = React.useState(null);
  const [loading, set_loading] = React.useState(false);
  const [handleDate, set_handleDate] = React.useState({});

  React.useEffect(() => {
    const today = moment().endOf('day');
    let defaultStartDate = moment().startOf('year');
    if (today.get('month') >= 7) {
      defaultStartDate = moment().startOf('year').add(6, 'months');
    }
    setInputs({ ...inputs, startDate: defaultStartDate, endDate: today });
    set_handleDate({ startDate: defaultStartDate, endDate: today });
  }, []);

  function getAttendantReport() {
    set_loading(true);

    const { startDate, endDate, seminary } = inputs;

    let body = {
      startDate,
      endDate,
      seminary,
    };

    let URL = `/seminaries/getAllSeminariesAttendanceReports`;
    let fileName = `InformeAsistenciaSeminario${seminary}.xlsx`;

    reportService
      .getReport({ URL, fileName, body })
      .then((response) => {
        set_loading(false);
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loading(false);
      });
  }

  const handleDateChange = (startDate, endDate) => {
    setInputs({
      ...inputs,
      startDate: startDate ? startDate.startOf('day')._d.getTime() : null,
      endDate: endDate ? endDate.endOf('day')._d.getTime() : null,
    });
    set_handleDate({ startDate, endDate });
  };

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>Reporte Seminarios</Button>}
    >
      <Modal.Header>Descargar reporte de los seminarios</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <h2>Filtros</h2>
          <p>
            <small>El rango se basa en la ultima interacción del asistente</small>
          </p>
          <div
            style={{
              display: 'inline-block',
              margin: '7px',
            }}
          >
            <label style={{ display: 'block' }} htmlFor={'date'}>
              Rango
            </label>
            <DateRangePicker
              endDate={handleDate.endDate}
              endDateId="endDateRange"
              endDatePlaceholderText="Fecha Fin"
              focusedInput={focused}
              isOutsideRange={() => false}
              minimumNights={0}
              onDatesChange={({ startDate, endDate }) => handleDateChange(startDate, endDate)}
              onFocusChange={(focused) => set_focused(focused)}
              renderMonthElement={renderMonthElement}
              startDate={handleDate.startDate}
              startDateId="startDateRange"
              startDatePlaceholderText="Fecha Inicio"
            />
          </div>
          <div className="selectorCheck">
            <Select
              placeholder="Paso de Crecer"
              onChange={selectHandle}
              name="seminary"
              value={inputs.seminary}
              options={seminaryEvent}
            />
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={loading}
          loading={loading}
          onClick={() => {
            setOpen(false);
            setInputs({});
          }}
        >
          Cerrar
        </Button>
        <Button
          style={{ margin: '5px' }}
          secondary
          disabled={loading}
          loading={loading}
          onClick={() => getAttendantReport()}
        >
          Descargar reporte
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default SeminaryReport;
