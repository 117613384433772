import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { errorInput } from '../../components/helpers';
import { Button } from 'semantic-ui-react';
import groupService from '../../services/groupService';
import Swal from 'sweetalert2';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  RelacionesSanasLeaderCompText,
  RelacionesSanasCompText,
  LoveAndRespectCompText,
  LoveAndRespectLeaderCompText,
  WeddingProjectLeaderCompText,
  WeddingProjectCompText,
} from './compliances/ComplianceTexts';
import GroupsComplianceForm from './compliances/GroupsComplianceForm';
import { habeasData } from '../helpers/habeasData';

const GroupCompliance = (props) => {
  const [inputs, setInputs] = React.useState({});
  const [errorInputs, set_errorInputs] = React.useState({});
  const [sending, set_sending] = React.useState(false);

  let document = {
    liderproyectomatrimonio: {
      type: 'couple',
      component: WeddingProjectLeaderCompText,
    },
    proyectomatrimonio: { type: 'couple', component: WeddingProjectCompText },
    liderparasiempre: {
      type: 'couple',
      component: LoveAndRespectLeaderCompText,
    },
    parasiempre: { type: 'couple', component: LoveAndRespectCompText },
    liderrelacionessanas: {
      type: 'single',
      component: RelacionesSanasLeaderCompText,
    },
    relacionessanas: { type: 'single', component: RelacionesSanasCompText },
  };

  const doc = props.match.params.doc;

  let canvasSign = {};
  let parentCanvasSign = {};

  const form_verification = () => {
    let mandatoryInputs = ['fullName', 'identification'];

    let errorInputs = {};

    mandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: inputs[input] },
      });
    });

    errorInputs = errorInput({
      errorInputs,
      input: { name: 'sign', value: !canvasSign.isEmpty() },
    });

    if (false && inputs['captcha'] && inputs['captcha'] !== 'null') {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'captcha',
          value: inputs?.['captcha']?.length >= 10,
        },
      });
    }

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  function captchaOnChange(value) {
    setInputs({ ...inputs, captcha: value });
  }

  function sendForm(e) {
    if (form_verification()) return;
    set_sending(true);

    const sign = JSON.stringify(canvasSign.toData().flat());
    const parentSign = JSON.stringify(parentCanvasSign.toData().flat());

    const body = {
      ...inputs,
      sign,
      parentSign,
      docName: doc,
      type: document?.[doc]?.type || '',
    };

    groupService
      .complianceForms(body)
      .then((response) => {
        if (response.data.success) {
          Swal.fire('Acuerdo enviado correctamente');
          setInputs({});
        }
        set_sending(false);
      })
      .catch((error) => {
        set_sending(false);
        if (error) {
          console.log(error);
          Swal.fire('Hay un error en el formulario, por favor revisa y vuelve a intentar.');
        }
      });
  }

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const input = ({ input, type = 'input', placeholder = '' }) => {
    return (
      <input
        type={type}
        name={input}
        placeholder={placeholder}
        className={`inline-form ${errorInputs[input] ? 'error' : ''}`}
        value={inputs[input] || ''}
        onChange={handleInputs}
      />
    );
  };

  const selectkindofid = () => {
    return (
      <select
        id="kind"
        onChange={(e) =>
          handleInputs({
            target: { value: e.target.value, name: 'kindofIdentification' },
          })
        }
        value={inputs.kindofIdentification}
      >
        <option value="C.C">C.C</option>
        <option value="C.E">C.E</option>
        <option value="T.I">T.I</option>
        <option value="PP">PP</option>
        <option value="PEP">PEP</option>
      </select>
    );
  };

  const today = new Date();
  return (
    <div className="volunteer-registration">
      <h1>IGLESIA COMUNIDAD CRISTIANA DE FE Y VIENTO FRESCO</h1>
      <p className="nit">Nit. 811.000.923-5</p>
      <p className="personeria">
        Personería Jurídica Personería Jurídica Especial No. 2675 del 18 de Diciembre 1997 del
        Ministerio del Interior
      </p>
      <p className="exoneracion">
        En todos los casos y eventos se deberá firmar este documento de exoneración de
        responsabilidad civil, legal y administrativa, como requisito indispensable para participar
        en el encuentro, campamento o evento. En caso de que el participante sea un menor de edad o
        un persona con capacidades especiales, siempre debe contar con la respectiva autorización
        escrita de sus padres y/o tutor legal.
      </p>
      <GroupsComplianceForm input={input} selectkindofid={selectkindofid} />
      {document[doc].component()}
      <div className="inner">
        <div className="signs-form">
          <div className="volunteerSign acudiente">
            <div className="firstSign">
              <p>
                <strong>AUTORIZACIÓN PARA MENORES DE EDAD</strong>
              </p>
              <p>
                <i>
                  *La firma del padre, madre, acudiente y/o tutor legal es requerida si el
                  participante es menor de edad o es una persona con capacidades especiales.
                </i>
              </p>
              <p style={{ fontStyle: 'normal' }}>
                Nombres y apellidos del Padre y/o Tutor legal que autoriza la participación del
                menor o de la persona con capacidades especiales{' '}
                {input({ input: 'parentFullName', placeholder: 'Nombre completo' })}, con documento
                de identificación {selectkindofid()} No.{' '}
                {input({
                  input: 'parentIdentification',
                  type: 'number',
                  placeholder: 'Documento tutor',
                })}
              </p>
              <p style={{ fontStyle: 'normal', fontWeight: 'bold' }}>
                Firma del padre, madre, acudiente y/o tutor legal
              </p>
              <SignatureCanvas
                penColor="black"
                ref={(ref) => {
                  parentCanvasSign = ref;
                }}
                canvasProps={{
                  width: 300,
                  height: 200,
                  className: `sigCanvas  ${errorInputs['sign'] ? 'error' : ''}`,
                }}
              />
              <Button
                style={{ display: 'block', margin: ' 7px 0' }}
                size="mini"
                onClick={() => parentCanvasSign.clear()}
              >
                Limpiar firma
              </Button>
            </div>
          </div>
          <p>
            Fecha de firma: <b>{today.getDate()}</b> del mes de{' '}
            <b>{today.toLocaleDateString('es-ES', { month: 'long' })}</b> de{' '}
            <b>{today.getFullYear()}</b>
          </p>
          <div className="volunteerSign">
            <div className="firstSign">
              <p style={{ fontStyle: 'normal', fontWeight: 'bold' }}>Firma participante</p>
              <SignatureCanvas
                penColor="black"
                ref={(ref) => {
                  canvasSign = ref;
                }}
                canvasProps={{
                  width: 300,
                  height: 200,
                  className: `sigCanvas  ${errorInputs['sign'] ? 'error' : ''}`,
                }}
              />
              <p>
                <strong>{inputs.fullName}</strong>
              </p>
              <p>{inputs.identification}</p>
              <Button
                style={{ display: 'block', margin: ' 7px 0' }}
                size="mini"
                onClick={() => canvasSign.clear()}
              >
                Limpiar firma
              </Button>
            </div>

            <div className="actions">
              <div className={`captcha ${errorInputs.captcha ? 'error' : ''}`}>
                <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA} onChange={captchaOnChange} />
              </div>
              <p style={{ color: 'red' }}>
                {errorInputs.captcha ? 'Por favor resuelve el captcha.' : ''}
              </p>
              {Object.values(errorInputs).includes(true) && (
                <h4 style={{ color: 'red' }}>
                  Por favor verifica los campos obligatorios en rojo.
                </h4>
              )}
              {habeasData}
              <Button onClick={() => sendForm()} primary disabled={sending}>
                Enviar
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GroupCompliance;
