import React from 'react';
import { Button, Checkbox } from 'semantic-ui-react';
import moment from 'moment';
import { Margin, usePDF } from 'react-to-pdf';
import { scrollUp } from '../../helpers';
import styled from '@emotion/styled';
import Swal from 'sweetalert2';
import groupService from '../../../services/groupService';
import { newLeadersQuestions } from './newLeadersQuestions';

const ResultsComp = styled.div`
  background-color: white;
  min-width: 80%;
  .info {
    padding: 40px;
    .userdata {
      display: inline-block;
      margin-right: 20px;
    }
  }
  h1 {
    padding: 20px 0;
  }
  .content {
    h2 {
      &.title {
        padding: 30px 0;
      }
    }
    .prospect,
    .leader {
      margin: 20px 0;
      h2 {
        padding: 10px 0 0 0;
        color: #2185d0;
        text-align: center;
      }
      .question {
        margin: 20px 0;
        p {
          margin: 10px 0;
          &.answer {
            font-style: italic;
          }
        }
      }
    }
  }
`;

const NewLeaderAnswers = (props) => {
  const { answers, set_showAnswers, getInfo } = props;
  const [sending, set_sending] = React.useState(false);
  const { toPDF, targetRef } = usePDF({
    method: 'save',
    page: { margin: Margin.MEDIUM },
    filename: 'respuestas-nuevo-aspirante-grupos.pdf',
  });

  React.useEffect(() => {
    scrollUp();
  }, []);

  const setVars = (toUpdate) => {
    let body = {
      id: answers?.id,
    };
    if (toUpdate === 'deleted') body.deleted = !answers?.deleted;
    if (toUpdate === 'survey') body.survey = !answers?.survey;

    set_sending(true);
    groupService
      .updateNewLeaderForm(body)
      .then((response) => {
        set_sending(false);
        if (response.data.success) {
          getInfo();
        }
        Swal.fire(response?.data?.message);
      })
      .catch((error) => {
        set_sending(false);
        Swal.fire(error?.response?.data?.message);
      });
  };

  const deleteProspectSurvey = () =>
    Swal.fire({
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar',
      showCancelButton: true,
      title: `¿Está seguro que quieres eliminar esta encuesta?`,
    }).then(async (result) => {
      if (result.value) {
        setVars('deleted');
        set_showAnswers(null);
      }
    });

  const condenseAnswers = (answers) => {
    let condensed = {};
    for (let key in answers) {
      if (typeof answers[key] === 'object') {
        condensed = { ...condensed, ...answers[key] };
      } else {
        condensed[key] = answers[key];
      }
    }
    return condensed;
  };

  const showAnswers = (questions, answers) => {
    let questionType = ({ text, type, onlyInQuestions }) => {
      if (onlyInQuestions) {
        return null;
      } else if (type === 'title') {
        return <h2>{text}</h2>;
      } else if (type === 'text') {
        return <p>{text}</p>;
      } else {
        return <h3>{text}</h3>;
      }
    };
    return questions?.flat()?.map((question) => (
      <div key={question.id} className="question">
        {questionType(question)}
        <p className="answer">{condenseAnswers(answers)?.[question.id]}</p>
      </div>
    ));
  };

  const guestData = (guest) => (
    <div>
      <p className="userdata">
        <strong>
          {guest?.name} {guest?.lastName}
        </strong>
      </p>
      <p className="userdata">
        Telefono: <strong>{guest?.phone ?? answers?.prospect_phone}</strong>
      </p>
      {!!guest?.birthdate && (
        <p className="userdata">
          Edad: <strong>{moment().diff(guest?.birthdate, 'years')}</strong>
        </p>
      )}
      <p className="userdata">
        Genero: <strong>{guest?.gender}</strong>
      </p>
      <p className="userdata">
        Identificación: <strong>{guest?.identification}</strong>
      </p>
      <p className="userdata">
        Email: <strong>{guest?.email}</strong>
      </p>
    </div>
  );

  return (
    <ResultsComp>
      <div className="info">
        <Button
          primary
          compact
          disabled={sending}
          onClick={() => set_showAnswers(null)}
          style={{ display: 'block', margin: '10px 0' }}
        >
          Ir Atrás
        </Button>
        <h1>Respuestas</h1>
        <div style={{ margin: '10px  0' }}>
          <Button disabled={sending} onClick={deleteProspectSurvey}>
            Eliminar encuesta
          </Button>
        </div>
        <Button onClick={toPDF}>Guardar formulario</Button>
        <div ref={targetRef} className="printable content">
          <strong>Respuestas de</strong>{' '}
          {!!answers.maleNewLeader?.id && guestData(answers.maleNewLeader)}
          {!!answers.femaleNewLeader?.id && guestData(answers.femaleNewLeader)}
          <p className="userdata">
            Fecha de llenado:{' '}
            <strong>{moment.tz(answers.createdAt, 'America/Bogota').format('DD-MM-YYYY')}</strong>
          </p>
          <div style={{ margin: '15px  0' }}>
            <Checkbox
              label="¿Tiene el aval del lider?"
              name="survey"
              checked={answers?.survey}
              disabled={sending}
              toggle
              onClick={() => setVars('survey')}
            />
          </div>
          <div className="prospect">
            <h2 className="title">Formulario del Nuevo Aspirante</h2>
            {showAnswers(newLeadersQuestions[answers?.type], answers)}
          </div>
        </div>
      </div>
    </ResultsComp>
  );
};
export default NewLeaderAnswers;
