export const newLeadersRequirementLists = {
  Grupo_de_Discipulado: {
    text: `Para ser líder de grupo de discipulado es necesario: \n 1.  Asistir activamente en un grupo de discipulado como mínimo hace 6 meses. \n 2. Tener el aval de tu líder. \n 3. Haber realizado los pasos de crecer incluyendo Influencia. \n 4. Realizar el proceso de Entrevista.`,
    type: 'richText',
    onlyInQuestions: true,
  },
  Couple_Seminaries: {
    text: `Para ser facilitador en entrenamiento es necesario: \n \n 1. Haber recibido el seminario completo. \n 2. Asistir activamente en un grupo de discipulado como mínimo hace 6 meses. \n 3. Tener el aval de tu líder de grupo. \n 4. Haber realizado los pasos de crecer hasta vida. \n 5. Estar casados.`,
    type: 'richText',
    onlyInQuestions: true,
  },
  Relaciones_Sanas: {
    text: `Para ser facilitador en entrenamiento es necesario: \n \n 1. Haber recibido el seminario completo. \n 2. Asistir activamente en un grupo de discipulado como mínimo hace 6 meses.  \n 3. Tener el aval de tu líder de grupo. \n 4. Haber realizado los pasos de crecer hasta influencia o estar realizando influencia en el momento.`,
    type: 'richText',
    onlyInQuestions: true,
  },
  Transformacion_Empresarial: {
    text: `Para ser facilitador en entrenamiento es necesario:  \n \n 1. Haber recibido el seminario completo. \n 2. Asistir activamente en un grupo de discipulado como mínimo hace 6 meses. \n 3. Tener el aval de tu líder de grupo. \n 4. Haber realizado los pasos de crecer hasta vida.`,
    type: 'richText',
    onlyInQuestions: true,
  },
  Escuela_de_Heroes: {
    text: `Para ser facilitador en entrenamiento es necesario:  \n \n 1. Haber recibido el seminario completo. \n 2. Asistir activamente en un grupo de discipulado como mínimo hace 6 meses. \n 3. Tener el aval de tu líder de grupo. \n 4. Haber realizado los pasos de crecer hasta vida.`,
    type: 'richText',
    onlyInQuestions: true,
  },
  Entrenador_de_Equipo: {
    text: `Para ser entrenador de un equipo de Líderes, es necesario: \n \n 1. Tener como mínimo 1 líder que lleva discipulando entre 4 a 6 meses. \n 2. Debe haber realizado todos los procesos de Crecer. \n 3. Tiene un nivel de madurez espiritual, en carácter y en discipulado. \n 4. Está conectado con la visión de comunidad de formar a otros discipuladores. \n 5. Tiene el aval de su líder. `,
    type: 'richText',
    onlyInQuestions: true,
  },
};
export const newLeadersQuestions = {
  Grupo_de_Discipulado: [
    [
      {
        text: 'Solicitud para ser Líder de Grupo de Discipulado',
        type: 'title',
      },
      newLeadersRequirementLists?.Grupo_de_Discipulado,
      {
        text: 'Siendo así, te invitamos a diligenciar las siguientes preguntas para validar los requisitos y guiarte en el proceso.',
        type: 'text',
        onlyInQuestions: true,
      },
      {
        text: '¿Asistes activamente en un grupo de discipulado como mínimo hace 6 meses?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_0',
      },
      {
        text: '¿Has realizado los pasos de CRECER incluyendo INFLUENCIA?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_1',
      },
      {
        text: '¿Tienes el aval del líder para abrir un grupo de discipulado?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_2',
      },
      {
        type: 'nextButton',
      },
    ],
    [
      {
        text: 'Solicitud para ser Líder de Grupo de Discipulado',
        type: 'title',
        onlyInQuestions: true,
      },
      {
        text: 'Llena tu información',
        type: 'form',
        onlyInQuestions: true,
      },
      {
        text: 'Nombre de tu lider',
        type: 'input',
        id: 'personal_leader_name',
      },
      {
        text: 'Teléfono de tu lider',
        type: 'input',
        kind: 'number',
        id: 'personal_leader_phone',
      },
      {
        type: 'prevButton',
      },
      {
        type: 'nextButton',
      },
    ],
    [
      {
        text: 'Solicitud para ser Líder de Grupo de Discipulado',
        type: 'title',
        onlyInQuestions: true,
      },
      newLeadersRequirementLists?.Grupo_de_Discipulado,
      {
        text: 'Si cumples con estos puntos te invitamos a esperar la entrevista realizada por el equipo de discipulado y luego recibir la capacitación para líderes nuevos.',
        type: 'text',
        onlyInQuestions: true,
      },
      {
        text: 'Nota: No abrir grupo de discipulado sin tener todo el proceso completo.',
        type: 'text',
        onlyInQuestions: true,
      },
      {
        type: 'endInfo',
        id: 'captcha',
      },
      {
        type: 'prevButton',
      },
      {
        type: 'send',
      },
    ],
  ],
  Couple_Seminaries: [
    [
      {
        text: 'Facilitadores en entrenamiento seminario Para Siempre y Proyecto Matrimonio',
        type: 'title',
      },
      newLeadersRequirementLists?.Couple_Seminaries,
      {
        text: 'Siendo así, te invitamos a diligenciar las siguientes preguntas para validar los requisitos y guiarte en el proceso.',
        type: 'text',
        onlyInQuestions: true,
      },
      {
        text: '¿Asisten activamente en un grupo de discipulado como mínimo hace 6 meses?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_0',
      },
      {
        text: '¿Tienen el aval del líder del grupo de discipulado?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_1',
      },
      {
        text: '¿El terminó CRECER hasta el paso VIDA?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_2',
      },
      {
        text: '¿Ella terminó CRECER hasta el paso VIDA?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_3',
      },
      {
        text: '¿Lograron terminar el seminario Para Siempre (Amor y Respeto) completamente?',
        type: 'select',
        options: [
          'Si, terminamos el seminario Para Siempre (Amor y Respeto)',
          'Si, terminamos el seminario Proyecto Matrimonio',
          'Si, terminamos ambos seminarios',
          'No',
        ],
        id: 'conditional_questions_4',
      },
      {
        type: 'nextButton',
      },
    ],
    [
      {
        text: 'Facilitadores en entrenamiento seminario Para Siempre y Proyecto Matrimonio',
        type: 'title',
        onlyInQuestions: true,
      },
      {
        text: 'Llena la información de él',
        type: 'form_male',
        onlyInQuestions: true,
      },
      {
        text: 'Nombre del lider de él',
        type: 'input',
        id: 'personal_his_leader_name',
      },
      {
        text: 'Teléfono del lider de él',
        type: 'input',
        kind: 'number',
        id: 'personal_his_leader_phone',
      },
      {
        text: 'Llena la información de ella',
        type: 'form_female',
        onlyInQuestions: true,
      },
      {
        text: 'Nombre del lider de ella',
        type: 'input',
        id: 'personal_her_leader_name',
      },
      {
        text: 'Teléfono del lider de ella',
        type: 'input',
        kind: 'number',
        id: 'personal_her_leader_phone',
      },
      {
        text: '¿Están Casados?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'personal_are_married',
      },
      {
        text: 'Nombre de la última pareja de facilitadores del seminario',
        type: 'input',
        id: 'info_last_facilitator_couple_name',
      },
      {
        text: 'Celular de la última pareja de facilitadores del seminario',
        type: 'input',
        kind: 'number',
        id: 'info_last_facilitator_couple_phone',
      },
      {
        text: '¿En cuál seminario desean ser facilitadores en entrenamiento?',
        type: 'select',
        options: ['Para Siempre', 'Proyecto Matrimonio'],
        id: 'info_couple_seminary',
      },
      {
        type: 'prevButton',
      },
      {
        type: 'nextButton',
      },
    ],
    [
      {
        text: 'Facilitadores en entrenamiento seminario Para Siempre y Proyecto Matrimonio',
        type: 'title',
        onlyInQuestions: true,
      },
      newLeadersRequirementLists?.Couple_Seminaries,
      {
        text: 'Si cumplen con todos los puntos, los estaremos contactando luego de validar la información. Si te falta alguno de los puntos, te invitamos a realizarlo para que en el próximo semestre puedan continuar con el proceso. ',
        type: 'text',
        onlyInQuestions: true,
      },
      {
        type: 'endInfo',
        id: 'captcha',
      },
      {
        type: 'prevButton',
      },
      {
        type: 'send',
      },
    ],
  ],
  Relaciones_Sanas: [
    [
      {
        text: 'Facilitador en entrenamiento seminario "Relaciones sanas"',
        type: 'title',
      },
      newLeadersRequirementLists.Relaciones_Sanas,
      {
        text: 'Siendo así, te invitamos a diligenciar las siguientes preguntas para validar los requisitos y guiarte en el proceso.',
        type: 'text',
        onlyInQuestions: true,
      },
      {
        text: '¿Asistes activamente en un grupo de discipulado como mínimo hace 6 meses?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_0',
      },
      {
        text: '¿Tienes el aval del líder para abrir un grupo de discipulado?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_1',
      },
      {
        text: '¿Has realizado hasta el paso de crecer Influencia?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_2',
      },
      {
        text: '¿Has realizado el seminario de Relaciones Sanas?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_3',
      },
      {
        type: 'nextButton',
      },
    ],
    [
      {
        text: 'Facilitador en entrenamiento seminario "Relaciones sanas"',
        type: 'title',
        onlyInQuestions: true,
      },
      {
        text: 'Llena tu información',
        type: 'form',
        onlyInQuestions: true,
      },
      {
        text: '¿Eres líder de grupo de discipulado?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'info_has_been_leader',
      },
      {
        text: 'Nombre de tu lider',
        type: 'input',
        id: 'personal_leader_name',
      },
      {
        text: 'Teléfono de tu lider',
        type: 'input',
        kind: 'number',
        id: 'personal_leader_phone',
      },
      {
        type: 'prevButton',
      },
      {
        type: 'nextButton',
      },
    ],
    [
      {
        text: 'Facilitador en entrenamiento seminario "Relaciones sanas"',
        type: 'title',
        onlyInQuestions: true,
      },
      newLeadersRequirementLists.Relaciones_Sanas,
      {
        text: 'Si cumplen con todos los puntos, te estaremos contactando luego de validar la información. Si te falta alguno de los puntos, te invitamos a realizarlo para que en el próximo semestre puedas continuar con el proceso.',
        type: 'text',
        onlyInQuestions: true,
      },
      {
        type: 'endInfo',
        id: 'captcha',
      },
      {
        type: 'prevButton',
      },
      {
        type: 'send',
      },
    ],
  ],
  Transformacion_Empresarial: [
    [
      {
        text: 'Facilitador en entrenamiento seminario Transformación Empresarial',
        type: 'title',
      },
      newLeadersRequirementLists.Transformacion_Empresarial,
      {
        text: 'Siendo así, te invitamos a diligenciar las siguientes preguntas para validar los requisitos y guiarte en el proceso.',
        type: 'text',
        onlyInQuestions: true,
      },
      {
        text: '¿Asistes activamente en un grupo de discipulado como mínimo hace 6 meses?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_0',
      },
      {
        text: '¿Tienes el aval del líder de tu grupo de discipulado?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_1',
      },
      {
        text: '¿Has realizado Crecer hasta el paso de vida?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_2',
      },
      {
        text: '¿Has culminado el seminario de Transformación empresarial o Más allá del Éxito?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_3',
      },
      {
        type: 'nextButton',
      },
    ],
    [
      {
        text: 'Facilitador en entrenamiento seminario Transformación Empresarial',
        type: 'title',
        onlyInQuestions: true,
      },
      {
        text: 'Llena tu información',
        type: 'form',
        onlyInQuestions: true,
      },
      {
        text: 'Nombre de tu lider',
        type: 'input',
        id: 'personal_leader_name',
      },
      {
        text: 'Teléfono de tu lider',
        type: 'input',
        kind: 'number',
        id: 'personal_leader_phone',
      },
      {
        text: 'Nombre del facilitador que te acompañó en el seminario',
        type: 'input',
        id: 'info_facilitator_name',
      },
      {
        text: 'Celular del facilitador que te acompañó en el seminario',
        type: 'input',
        kind: 'number',
        id: 'info_facilitator_phone',
      },
      {
        type: 'prevButton',
      },
      {
        type: 'nextButton',
      },
    ],
    [
      {
        text: 'Facilitador en entrenamiento seminario Transformación Empresarial',
        type: 'title',
        onlyInQuestions: true,
      },
      newLeadersRequirementLists.Transformacion_Empresarial,
      {
        text: 'Si cumplen con todos los puntos, te estaremos contactando luego de validar la información. Si te falta alguno de los puntos, te invitamos a realizarlo para que en el próximo semestre puedas continuar con el proceso. ',
        type: 'text',
        onlyInQuestions: true,
      },
      {
        type: 'endInfo',
        id: 'captcha',
      },
      {
        type: 'prevButton',
      },
      {
        type: 'send',
      },
    ],
  ],
  Escuela_de_Heroes: [
    [
      {
        text: 'Facilitador en entrenamiento seminario Escuela de Heroes',
        type: 'title',
      },
      newLeadersRequirementLists.Escuela_de_Heroes,
      {
        text: 'Siendo así, te invitamos a diligenciar las siguientes preguntas para validar los requisitos y guiarte en el proceso.',
        type: 'text',
        onlyInQuestions: true,
      },
      {
        text: '¿Asistes activamente en un grupo de discipulado como mínimo hace 6 meses?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_0',
      },
      {
        text: '¿Tienes el aval del líder de tu grupo de discipulado?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_1',
      },
      {
        text: '¿Has realizado Crecer hasta el paso de vida?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_2',
      },
      {
        text: '¿Has culminado el seminario de Escuela de Heroes?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_3',
      },
      {
        type: 'nextButton',
      },
    ],
    [
      {
        text: 'Facilitador en entrenamiento seminario Escuela de Heroes',
        type: 'title',
        onlyInQuestions: true,
      },
      {
        text: 'Llena tu información',
        type: 'form',
        onlyInQuestions: true,
      },
      {
        text: 'Nombre de tu lider',
        type: 'input',
        id: 'personal_leader_name',
      },
      {
        text: 'Teléfono de tu lider',
        type: 'input',
        kind: 'number',
        id: 'personal_leader_phone',
      },
      {
        text: 'Nombre del facilitador que te acompañó en el seminario',
        type: 'input',
        id: 'info_facilitator_name',
      },
      {
        text: 'Celular del facilitador que te acompañó en el seminario',
        type: 'input',
        kind: 'number',
        id: 'info_facilitator_phone',
      },
      {
        type: 'prevButton',
      },
      {
        type: 'nextButton',
      },
    ],
    [
      {
        text: 'Facilitador en entrenamiento seminario Escuela de Heroes',
        type: 'title',
        onlyInQuestions: true,
      },
      newLeadersRequirementLists.Escuela_de_Heroes,
      {
        text: 'Si cumplen con todos los puntos, te estaremos contactando luego de validar la información. Si te falta alguno de los puntos, te invitamos a realizarlo para que en el próximo semestre puedas continuar con el proceso.',
        type: 'text',
        onlyInQuestions: true,
      },
      {
        type: 'endInfo',
        id: 'captcha',
      },
      {
        type: 'prevButton',
      },
      {
        type: 'send',
      },
    ],
  ],
  Entrenador_de_Equipo: [
    [
      {
        text: 'Entrenador de un Equipo de lideres',
        type: 'title',
      },
      newLeadersRequirementLists.Entrenador_de_Equipo,
      {
        text: 'Siendo así, te invitamos a diligenciar las siguientes preguntas para validar los requisitos y guiarte en el proceso.',
        type: 'text',
        onlyInQuestions: true,
      },
      {
        text: '¿Tienes como mínimo (1) un líder en tu grupo y ese líder lleva discipulando un grupo entre 4 y 6 meses?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_0',
      },
      {
        text: '¿Ya realizaste todos los procesos de crecer?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_1',
      },
      {
        text: '¿Te sientes conectado con la visión de comunidad de formar a otros discipuladores.?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_2',
      },
      {
        text: '¿Tienes el aval de tu líder?',
        type: 'select',
        options: ['Sí', 'No'],
        id: 'conditional_questions_3',
      },
      {
        type: 'nextButton',
      },
    ],
    [
      {
        text: 'Entrenador de un Equipo de lideres',
        type: 'title',
        onlyInQuestions: true,
      },
      {
        text: 'Llena tu información',
        type: 'form',
        onlyInQuestions: true,
      },
      {
        text: 'Nombre de tu lider',
        type: 'input',
        id: 'personal_leader_name',
      },
      {
        text: 'Teléfono de tu lider',
        type: 'input',
        kind: 'number',
        id: 'personal_leader_phone',
      },
      {
        type: 'prevButton',
      },
      {
        type: 'nextButton',
      },
    ],
    [
      {
        text: 'Entrenador de un Equipo de lideres',
        type: 'title',
        onlyInQuestions: true,
      },
      newLeadersRequirementLists.Entrenador_de_Equipo,
      {
        text: 'Si cumples con estos puntos, debes de esperar la capacitación para entrenadores nuevos; el área de discipulado se estará comunicando contigo para poder asistir.',
        type: 'text',
        onlyInQuestions: true,
      },
      {
        type: 'endInfo',
        id: 'captcha',
      },
      {
        type: 'prevButton',
      },
      {
        type: 'send',
      },
    ],
  ],
};
export const kindOfGroups = [
  {
    text: 'Grupo de Discipulado',
    value: 'Grupo_de_Discipulado',
  },
  {
    text: 'Entrenador de Equipo de Lideres',
    value: 'Entrenador_de_Equipo',
  },
];
export const kindOfSeminaries = [
  {
    text: 'Seminario de Proyecto Matrimonio',
    value: 'Couple_Seminaries',
  },
  {
    text: 'Seminario Para Siempre',
    value: 'Couple_Seminaries',
  },
  {
    text: 'Seminario de Relaciones Sanas',
    value: 'Relaciones_Sanas',
  },
  {
    text: 'Seminario Transformación Empresarial',
    value: 'Transformacion_Empresarial',
  },
  {
    text: 'Seminario Escuela de Héroes',
    value: 'Escuela_de_Heroes',
  },
];
export const formQuestionList = [
  {
    text: 'Solicitud para ser líder de grupo, entrenador de equipo o Facilitador de seminario',
    type: 'title',
  },
  {
    text: 'Gracias por responder al llamado de ser un líder o facilitador en Comunidad.',
    type: 'text',
  },
  {
    text: 'Por favor elige la opción de acuerdo a tu solicitud.',
    type: 'text',
  },
  {
    text: 'Quieres ser líder o entrenador de:',
    type: 'select',
    optionsObjects: kindOfGroups,
    id: 'formQuestion',
  },
  {
    text: 'Quieres ser facilitador de:',
    type: 'select',
    optionsObjects: kindOfSeminaries,
    id: 'formQuestion',
  },
];
export const endQuestionary = (formQuestion, validation) => [
  {
    text: 'Solicitud para ser líder de grupo, entrenador de equipo o Facilitador de seminario',
    type: 'title',
  },
  ...(formQuestion === 'Grupo_de_Discipulado'
    ? validation
      ? [
          {
            text: '¡Gracias por responder al llamado de ir y hacer discípulos! Te invitamos a esperar la entrevista realizada por el equipo de discipulado y luego recibir la capacitación para líderes nuevos.',
            type: 'text',
          },
          {
            text: 'Recuerda No abrir grupo de discipulado sin tener todo el proceso completo.',
            type: 'text',
          },
          {
            text: '¡Muchas gracias!',
            type: 'text',
          },
        ]
      : [
          {
            text: 'Para ser líder de grupo de discipulado, es necesario: Asistir a un grupo activamente como mínimo hace 6 meses, tener el aval del líder, haber realizado los pasos de crecer hasta influencia, y haber realizado el seminario Relaciones Sanas.',
            type: 'text',
          },

          {
            text: '¡Muchas gracias!',
            type: 'text',
          },
        ]
    : []),
  ...(formQuestion === 'Entrenador_de_Equipo'
    ? validation
      ? [
          {
            text: 'Gracias por responder al llamado de formar discipuladores! Te invitamos a esperar la  capacitación para nuevos entrenadores, el equipo de discipulado se pondrá en contacto.',
            type: 'text',
          },
        ]
      : [
          {
            text: '¡Gracias por responder al llamado de formar discipuladores!',
            type: 'text',
          },
          newLeadersRequirementLists?.Entrenador_de_Equipo,
          {
            text: '¡Muchas gracias!',
            type: 'text',
          },
        ]
    : []),
  ...(formQuestion === 'Couple_Seminaries' ||
  formQuestion === 'Relaciones_Sanas' ||
  formQuestion === 'Transformacion_Empresarial' ||
  formQuestion === 'Escuela_de_Heroes'
    ? [
        {
          text: 'Si cumplen con todos los puntos, los estaremos contactando luego de validar la información. Si te falta alguno de los puntos, te invitamos a realizarlo para que en el próximo semestre puedan continuar con el proceso.',
          type: 'text',
        },
        {
          text: '¡Muchas gracias!',
          type: 'text',
        },
      ]
    : []),
];
