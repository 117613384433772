import React from 'react';
import { Button, Modal, Header } from 'semantic-ui-react';
import groupService from '../../../services/groupService';
import Swal from 'sweetalert2';
import { avoCaptConstructor } from '../../helpers';
import { SingleDatePicker } from 'react-dates';
import { renderMonthElement } from '../../form/Form';
import moment from 'moment';

const GroupMemberModal = (props) => {
  const { member, getInfo, isCouples, isPendingNewMembers = false } = props;
  const [open, setOpen] = React.useState(false);
  const [editing, set_editing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [inputs, set_inputs] = React.useState({});
  const [focused, set_focused] = React.useState(false);

  const deleteGroupMember = async () => {
    const { value: text } = await Swal.fire({
      title: `¿Seguro que desea ${isPendingNewMembers ? 'no aceptar' : 'sacar'} a esta persona?`,
      text: 'Escribe la razón',
      input: 'text',
      inputPlaceholder: 'Escribe la razón',
      inputAttributes: {
        'aria-label': 'razon',
      },
      showCancelButton: true,
    });

    if (text) {
      setLoading(true);
      groupService
        .GroupMemberDelete({ id: member.id, group: member.group, statusObservation: text })
        .then((response) => {
          setLoading(false);
          if (response.data.success) {
            getInfo();
            setOpen(false);
            Swal.fire(response.data.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) Swal.fire(err.response.data.message);
        });
    }
  };

  const acceptGroupMember = async () => {
    Swal.fire({
      title: '¿Seguro que desea ingresar a esta persona a su grupo?',
      showCancelButton: true,
      confirmButtonText: 'Si, ingresar',
      cancelButtonText: 'Volver',
    }).then((result) => {
      if (result.value) {
        setLoading(true);
        groupService
          .GroupMemberAccept({ id: member.id, group: member.group, statusObservation: 'Ingresado' })
          .then((response) => {
            setLoading(false);
            if (response.data.success) {
              getInfo();
              setOpen(false);
              Swal.fire(response.data.message);
            }
          })
          .catch((err) => {
            setLoading(false);
            if (err.response) Swal.fire(err.response.data.message);
          });
      }
    });
  };

  const editGroupMember = async () => {
    setLoading(true);
    groupService
      .EditGroupMember({ ...inputs, id: member.id, group: member.group })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          getInfo();
          closeEditing();
          Swal.fire(response.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) Swal.fire(err.response.data.message);
      });
  };

  const memberInfo = (guest) => {
    return (
      <>
        <p>
          <b>
            {guest.name} {guest.lastName}
          </b>
        </p>
        <p>
          Identificación: <b>{guest.identification}</b>
        </p>
        {guest.email && (
          <p>
            Email: <b>{guest.email}</b>
          </p>
        )}
        {guest.phone && (
          <p>
            Celular: <b>{guest.phone}</b>
          </p>
        )}
        {guest.ocupation && (
          <p>
            Ocupación: <b>{guest.ocupation}</b>
          </p>
        )}
        <p>
          Cumpleaños: <b>{guest.birthdate}</b>
        </p>
        <a
          href={`/perfil/crecer?identification=${guest.identification}&phone=${
            guest.phone
          }&ac=${avoCaptConstructor(guest.identification)}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button>Ver perfil del discipulo</Button>
        </a>
      </>
    );
  };

  const startEditing = () => {
    set_editing(true);
    set_inputs({ startDate: member.startDate });
  };

  const closeEditing = () => {
    set_editing(false);
    set_inputs({});
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    set_inputs({ ...inputs, [name]: value });
  };
  console.log(member);
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>Ver</Button>}
    >
      <Modal.Content>
        <Modal.Description>
          {member.male && (
            <>
              <Header as="h2">Él</Header>
              {memberInfo(member.male)}
            </>
          )}
          {member.female && (
            <>
              <Header as="h2">Ella</Header>
              {memberInfo(member.female)}
            </>
          )}
          {isCouples && (
            <>
              <Header as="h2">Pareja</Header>
              <p>
                Tipo de unión: <b>{member.uniontype}</b>
              </p>
            </>
          )}
          {!isPendingNewMembers && (
            <div style={{ margin: '10px 0' }}>
              {editing ? (
                <div>
                  <div>
                    <label htmlFor={'startDate'}>Fecha de ingreso al grupo: </label>{' '}
                    <SingleDatePicker
                      date={
                        new Date(inputs.startDate) === 'Invalid Date' || !inputs.startDate
                          ? null
                          : moment(inputs.startDate)
                      }
                      onDateChange={(newDate) =>
                        handleInputs({
                          target: {
                            value: newDate ? newDate._d : newDate,
                            name: 'startDate',
                          },
                        })
                      }
                      focused={focused}
                      disabled={loading}
                      onFocusChange={({ focused }) => set_focused(focused)}
                      renderMonthElement={renderMonthElement}
                      placeholder="dd/mm/aaaa"
                      showDefaultInputIcon={true}
                      numberOfMonths={1}
                      displayFormat="DD/MM/YYYY"
                      isOutsideRange={() => false}
                      id="startDate"
                    />
                  </div>
                  <Button disabled={loading} onClick={closeEditing}>
                    Cancelar
                  </Button>
                  <Button primary disabled={loading} onClick={editGroupMember}>
                    Guardar
                  </Button>
                </div>
              ) : (
                <>
                  <p style={{ margin: '0' }}>
                    Fecha de ingreso a este grupo:{' '}
                    <b>{moment(member.startDate).format('DD-MMM-yyyy')}</b>
                  </p>
                  <Button size="tiny" onClick={startEditing}>
                    Editar
                  </Button>
                </>
              )}
            </div>
          )}
          {isPendingNewMembers && (
            <Button basic color="black" onClick={() => acceptGroupMember()}>
              Ingresar
            </Button>
          )}
          <Button basic color="red" onClick={() => deleteGroupMember()}>
            {isPendingNewMembers ? 'Rechazar' : 'Sacar del grupo'}
          </Button>
          <Button disabled={loading} onClick={() => setOpen(false)} secondary>
            Cerrar
          </Button>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default GroupMemberModal;
