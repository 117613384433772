import moment from 'moment';
import { Button } from 'semantic-ui-react';
import { kindOfGroups, kindOfSeminaries } from '../../groups/newLeaders/newLeadersQuestions';

export const NewLeadersRowDef = ({ set_showNewLeaderForm, loading }) => {
  return [
    {
      headerName: 'Formulario',
      children: [
        {
          headerName: 'Fecha',
          width: 140,
          field: 'createdAt',
          valueGetter: (params) =>
            moment.tz(params?.data?.createdAt, 'America/Bogota').format('DD-MM-YYYY'),
        },
        {
          headerName: 'Tipo',
          width: 160,
          field: 'type',
          valueGetter: (params) =>
            params?.data?.info?.info_couple_seminary ??
            [...kindOfGroups, ...kindOfSeminaries]?.find(
              (kind) => kind.value === params?.data?.type
            )?.text,
        },
      ],
    },
    {
      headerName: 'Datos aspirantes',
      children: [
        {
          headerName: 'Nombre El',
          width: 160,
          field: 'his_name',
          valueGetter: (params) =>
            `${params?.data?.maleNewLeader?.name || ''} ${
              params?.data?.maleNewLeader?.lastName || ''
            }`,
        },
        {
          headerName: 'Nombre Ella',
          width: 160,
          field: 'her_name',
          valueGetter: (params) =>
            `${params?.data?.femaleNewLeader?.name || ''} ${
              params?.data?.femaleNewLeader?.lastName || ''
            }`,
        },
      ],
    },
    {
      headerName: 'Acciones',
      children: [
        {
          headerName: 'Aval del lider',
          width: 120,
          field: 'survey',
          valueGetter: (params) => `${params?.data?.survey ? 'Si' : 'No'}`,
        },
        {
          headerName: 'Ver',
          field: 'actions',
          width: 135,
          cellRenderer: (params) => (
            <Button
              size="tiny"
              disabled={loading}
              onClick={() => set_showNewLeaderForm(params?.data?.id)}
            >
              Ver
            </Button>
          ),
        },
      ],
    },
  ];
};
