import { useState, useEffect } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import groupService from '../../../services/groupService';
import { Button } from 'semantic-ui-react';
import { changeDocTitle } from '../../helpers';
import { Link } from 'react-router-dom';
import Table from '../../table/aggrid';
import { NewLeadersRowDef } from '../../common/adminTable/NewLeadersRow';
import NewLeaderAnswers from './NewLeaderAnswers';

const AllNewLeaders = (props) => {
  const [newLeaders, set_newLeaders] = useState([]);
  const [loading, set_loading] = useState(true);
  const [showNewLeaderForm, set_showNewLeaderForm] = useState(null);

  useEffect(() => {
    changeDocTitle('Nuevos Aspirantes a Lideres');
    getInfo();
  }, []);

  function getInfo() {
    set_loading(true);
    groupService
      .getAllNewLeaders()
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          props.history.push('?keyword=');
          set_newLeaders(response.data.newLeaders);
        } else {
          set_loading(null);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loading(false);
      });
  }

  if (showNewLeaderForm) {
    return (
      <NewLeaderAnswers
        id={showNewLeaderForm}
        set_showAnswers={set_showNewLeaderForm}
        answers={newLeaders.find((form) => form.id === showNewLeaderForm)}
        getInfo={getInfo}
      />
    );
  }

  return (
    <section className="AdminTable">
      <div className="AdminTable__content">
        <div>
          <Link to="/admin?folder=groups">
            <Button primary>Atrás</Button>
          </Link>
          <Button primary disabled={loading} onClick={getInfo}>
            Recargar
          </Button>
        </div>
      </div>
      <div>
        {!!loading && <div className="loading">Cargando...</div>}
        <Table
          rowData={newLeaders}
          rowHeight={50}
          columnDefs={NewLeadersRowDef({
            set_showNewLeaderForm,
            loading,
          })}
          suppressDragLeaveHidesColumns={false}
          pagination={true}
        />
      </div>
    </section>
  );
};

export default AllNewLeaders;
