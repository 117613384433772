import { useState, useEffect } from 'react';
import { DashboardCrecerComponent } from './styles/DashboardCrecerComponent';
import membersProgressService from '../../../services/adminService';
import reportService from '../../../services/reportService';
import Swal from 'sweetalert2';
import LineChartSection from "../utils/LineChartSection";
import PieChartComponent from '../utils/PieChartComponent';

const Crecer = () => {
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(true);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(false);
  const [startDate, setStartDate] = useState('2024-06-01');
  const [endDate, setEndDate] = useState('2024-12-31');
  const [classesAttended, setClassesAttended] = useState('16');
  const [step, setStep] = useState('vida');
  const [stepAtt, setStepAtt] = useState('nuevos');
  const [allPeople, setAllPeople] = useState([]);
  const [genders, setGenders] = useState([]);
  const [byClass, setByClass] = useState([]);
  const [allSteps, setAllSteps] = useState([]);
  const [filterByClass, setFilterByClass] = useState([false]);

  const steps = ['Todos', 'vida', 'influencia', 'encuentro', 'bautismo'];
  const stepsAttendance = ['nuevos', 'vida', 'influencia', 'encuentro', 'bautismo'];
  const width = 600;
  let lines = 6;
  const lineThickness = 40;
  const baseWidth = 250;
  const spacing = baseWidth / lines / 2;


  const filterGenders = (attendants) => {
    const total = attendants.length;
    const men = attendants.filter((attendant) => attendant.Guest.gender === 'Masculino').length;
    const women = attendants.filter((attendant) => attendant.Guest.gender === 'Femenino').length;
    const unknow = attendants.filter(
      (attendant) =>
        !attendant.Guest.gender ||
        (attendant.Guest.gender !== 'Masculino' && attendant.Guest.gender !== 'Femenino')
    ).length;
    const percentageMen = ((men / total) * 100).toFixed(1);
    const percentagewoman = ((women / total) * 100).toFixed(1);
    const percentageunknow = ((unknow / total) * 100).toFixed(1);
    const genders = [
      { name: 'Hombres', value: men, percentage: percentageMen },
      { name: 'Mujeres', value: women, percentage: percentagewoman },
      { name: 'Desconocido', value: unknow, percentage: percentageunknow },
    ];
    return genders;
  };

  const allCrecerAttendance = () => {
    if (step === 'Todos') {
      membersProgressService
        .getAllCrecerSteps({ startDate, endDate, classesAttended })
        .then((res) => {
          const bautismo = filterGenders(res.data.allSteps.bautismo);
          const conectar = filterGenders(res.data.allSteps.conectar);
          const encuentro = filterGenders(res.data.allSteps.encuentro);
          const influencia = filterGenders(res.data.allSteps.influencia);
          const vida = filterGenders(res.data.allSteps.vida);

          const allBautismo = [
            { name: 'Total Bautismo', value: res.data.allSteps.bautismo.length, percentage: 100 },
          ];
          const allConectar = [
            { name: 'Total Conectar', value: res.data.allSteps.conectar.length, percentage: 100 },
          ];
          const allEncuentro = [
            { name: 'Total Encuentro', value: res.data.allSteps.encuentro.length, percentage: 100 },
          ];
          const allVida = [{ name: 'Total Vida', value: res.data.allSteps.vida.length, percentage: 100 }];
          const allInfluencia = [
            { name: 'Total Influencia', value: res.data.allSteps.influencia.length, percentage: 100 },
          ];

          const classVida = [
            {
              name: 'Filtro clase', value: res.data.allStepsFilteredByClasses.vida.length, percentage: (
                (res.data.allStepsFilteredByClasses.vida.length / res.data.allSteps.vida.length) *
                100
              ).toFixed(1),
            },
          ];
          const classInfluencia = [
            {
              name: 'Filtro clase', value: res.data.allStepsFilteredByClasses.influencia.length, percentage: (
                (res.data.allStepsFilteredByClasses.influencia.length / res.data.allSteps.influencia.length) *
                100
              ).toFixed(1),
            },
          ];

          const all = [
            { name: 'Conectar', value: conectar, allAttendance: allConectar },
            { name: 'Encuentro', value: encuentro, allAttendance: allEncuentro },
            { name: 'Bautismo', value: bautismo, allAttendance: allBautismo },
            { name: 'Vida', value: vida, allAttendance: allVida, filter: classVida },
            {
              name: 'Influencia',
              value: influencia,
              allAttendance: allInfluencia,
              filter: classInfluencia,
            },
          ];

          setAllSteps(all);
        });
    } else {
      membersProgressService
        .getAllCrecerAttendance({ step, startDate, endDate, classesAttended })
        .then((response) => {
          const progressData = response.data || [];
          const attendants = progressData.attendants || [];
          const filterByClass = progressData.filteredByClasses || [];

          const genders = filterGenders(attendants);
          setGenders(genders);

          //
          const allPeople = attendants.length;
          const processedData = [{ name: 'Total Personas', value: allPeople, percentage: 100 }];
          setAllPeople(processedData);

          if (filterByClass.length !== 0) {
            setFilterByClass(true);
            const perc = ((filterByClass.length / attendants.length) * 100).toFixed(1);
            const allByClass = [{ name: 'filtro por clase ', value: filterByClass.length, percentage: perc }];

            setByClass(allByClass);
          } else {
            setFilterByClass(false);
          }



        })
        .catch((error) => { });
    }
  };

  const fetchData = () => {
    setLoading(true);
    if (stepAtt === 'nuevos') {
      membersProgressService
        .getNewMembersProgress({ startDate, endDate })
        .then((response) => {
          const progressData = response.data || {};

          const funnelData = [
            { name: 'Nuevos Miembros', value: progressData.newMembers?.length || 0 },
            { name: 'Conectar', value: progressData.conectar?.length || 0 },
            { name: 'Encuentro', value: progressData.encuentro?.length || 0 },
            { name: 'Bautismo', value: progressData.bautismo?.length || 0 },
            { name: 'Vida', value: progressData.vida?.length || 0 },
            { name: 'Influencia', value: progressData.influencia?.length || 0 },
          ];

          setData(funnelData);
        })
        .catch((error) => {
          console.error('Error al obtener el progreso de los miembros:', error);
        })
        .finally(() => {
          setLoading(false);
          setSearch(false);
        });
    } else {
      const step = stepAtt;
      membersProgressService
        .crecerProgress({ startDate, endDate, step })
        .then((res) => {
          const progressData = res.data;

          const stepsMap = {
            encuentro: ['Encuentro', 'Bautismo', 'Vida', 'Influencia'],
            bautismo: ['Bautismo', 'Vida', 'Influencia'],
            vida: ['Vida', 'Influencia'],
            influencia: ['Influencia'],
          };

          const funnelData = (stepsMap[stepAtt] || []).map((step) => ({
            name: step,
            value: progressData[step.toLowerCase()]?.length || 0,
          }));

          setData(funnelData);
        })
        .finally(() => {
          setLoading(false);
          setSearch(false);
        });

    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFetchData = () => {
    fetchData();
  };

  const handleAttendance = () => {
    setLoading(true);
    allCrecerAttendance();
    setLoading(false);
  };

  function getAttendantReport() {
    setLoading(true);

    let body = {
      startDate,
      endDate,
      step,
      classesAttended,
    };

    let URL = `/crecer/getAllCrecerAttendanceReports`;
    let fileName = `InformeAsistenciaCrecer_${step}.xlsx`;

    reportService
      .getReport({ URL, fileName, body })
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        setLoading(false);
      });
  }

  const stepsData = {
    nuevos: { title: "Nuevos Miembros", lines: [...Array(5).keys()] },
    encuentro: { title: "Encuentro", lines: [...Array(4).keys()] },
    bautismo: { title: "Bautismo", lines: [...Array(3).keys()] },
    vida: { title: "Vida", lines: [...Array(2).keys()] },
    influencia: { title: "Influencia", lines: [...Array(1).keys()] },
  };


  return (
    <DashboardCrecerComponent>
      <h2 className='h2Title'>Crecer</h2>

      <div>
        <button
          onClick={() => setActiveTab(true)}
          className={`tabs ${activeTab ? 'active' : ''}`}
        >
          Asistencia
        </button>
        <button
          onClick={() => setActiveTab(false)}
          className={`tabs ${!activeTab ? 'active' : ''}`}
        >
          Reportes
        </button>
      </div>

      {activeTab && (
        <>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <label className="labels">Paso:</label>
              <select
                value={stepAtt}
                onChange={(e) => {
                  setStepAtt(e.target.value);
                  setSearch(true);
                }}
                style={{
                  width: '150px',
                }}
                className='inputs'
              >
                {stepsAttendance.map((step, index) => (
                  <option key={index} value={step}>
                    {step}
                  </option>
                ))}
              </select>
              <label className="labels">Desde:</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="inputs"
              />

              <label className="labels">Hasta:</label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="inputs"
              />

              <button
                className="button"
                onClick={handleFetchData}
              >
                Consultar
              </button>
            </div>
          </div>

          {loading && (
            <div className='containerSpinner'>
              <div className='spinner'></div>
            </div>
          )}
          {search && (
            <>
              <div className="whiteSpace">
                <p>Click en consultar</p>
              </div>
            </>
          )}
          {!loading && !search && (
            <>
              <div style={{ width: '100%' }}></div>
              <LineChartSection
                title={stepsData[stepAtt].title}
                linesArray={stepsData[stepAtt].lines}
                width={width}
                baseWidth={baseWidth}
                spacing={spacing}
                lineThickness={lineThickness}
                data={data}
              />
            </>
          )}
        </>
      )}

      {!activeTab && (
        <>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <label className="labels">Paso:</label>
              <select
                value={step}
                onChange={(e) => setStep(e.target.value)}
                style={{
                  width: '150px',
                }}
                className='inputs'
              >
                {/* Mapeamos la lista de 'steps' para generar las opciones */}
                {steps.map((step, index) => (
                  <option key={index} value={step}>
                    {step} {/* Aquí se muestra el valor de cada opción */}
                  </option>
                ))}
              </select>

              <label className="labels">Desde:</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="inputs"
              />

              <label className="labels">Hasta:</label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="inputs"
              />
              {step !== 'encuentro' && step !== 'bautismo' && (
                <>
                  <label style={{ marginRight: '8px' }}>cant clase:</label>
                  <input
                    type="text"
                    value={classesAttended}
                    onChange={(e) => setClassesAttended(e.target.value)}
                    style={{
                      borderRadius: '10px',
                      border: '1px solid #242323',
                      padding: '5px',
                      width: '80px',
                    }}
                  />
                </>
              )}

              <button
                className='button'
                onClick={handleAttendance}
              >
                Consultar
              </button>

              {step !== 'Todos' && (
                <button
                  className="button"
                  onClick={getAttendantReport}
                >
                  Descargar
                </button>
              )}
            </div>
          </div>
          {loading && (
            <div className='containerSpinner'>
              <div className='spinner'></div>
            </div>
          )}

          {!loading && (step !== 'Todos' ? (
            <>
              <div style={{ display: 'flex' }}>
                <PieChartComponent
                  title={''}
                  data={genders}
                  outerRadius={80}
                  width={400}
                  nameKey="name"
                />

                <PieChartComponent
                  title={''}
                  data={allPeople}
                  outerRadius={80}
                  width={450}
                  nameKey="name"
                />

                {filterByClass && (

                  <PieChartComponent
                    title={''}
                    data={byClass}
                    outerRadius={80}
                    width={450}
                    nameKey="name"
                  />
                )}
              </div>
            </>
          ) : (
            <div className='container-1'>
              <h3>Todos los pasos </h3>
              {allSteps.map((step, index) => (
                <div
                  key={index}
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  <h3>{step.name}</h3>
                  <PieChartComponent
                    title={''}
                    data={step.value}
                    outerRadius={80}
                    width={450}
                    nameKey="name"
                  />

                  <PieChartComponent
                    title={''}
                    data={step.allAttendance}
                    outerRadius={80}
                    width={450}
                    nameKey="name"
                  />


                  {step.filter && (

                    <PieChartComponent
                      title={''}
                      data={step.filter}
                      outerRadius={80}
                      width={450}
                      nameKey="name"
                    />
                  )}
                </div>

              ))}
              <br />
              <br />
              <br />
              <br />
            </div>
          ))}
        </>
      )}
    </DashboardCrecerComponent>
  );
};

export default Crecer;
