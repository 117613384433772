import { Button, Image } from 'semantic-ui-react';
import {
  checkAttendanceToVolunteer,
  checkSnackToVolunteer,
} from '../../../containers/experience/volunteersLogs/confirmationHelpers';
import { chPerm, profileImage } from '../../helpers';
import moment from 'moment';

export const ExperienceTeamCheckRowDef = ({
  loading,
  service,
  eventSlugname,
  getVolunteersByEvent,
  set_loading,
  showImgs,
  showCedNRole,
  showArea,
}) => [
  ...(showImgs
    ? [
        {
          headerName: 'Foto',
          field: 'picture',
          cellRenderer: (val) => (
            <Image src={profileImage(val.data?.Volunteer?.Guest)} rounded size="small" />
          ),
          width: 100,
        },
      ]
    : []),
  ...(showCedNRole
    ? [
        {
          headerName: 'Cédula',
          field: 'identification',
          valueGetter: (val) => val.data?.Volunteer?.Guest?.identification,
          width: 100,
        },
      ]
    : []),
  {
    headerName: 'Nombre',
    field: 'name',
    valueGetter: (val) =>
      `${val.data?.Volunteer?.Guest?.name} ${val.data?.Volunteer?.Guest?.lastName}`,
    width: 200,
  },
  ...(showArea
    ? [
        {
          headerName: 'Área de servicio',
          field: 'serviceArea',
          valueGetter: (val) => val.data?.Volunteer?.serviceArea,
          width: 150,
        },
      ]
    : []),
  ...(showCedNRole
    ? [
        {
          headerName: 'Rol',
          field: 'role',
          valueGetter: (val) => val.data?.Volunteer?.role,
          width: 150,
        },
      ]
    : []),
  ...(chPerm(['experienceCoord'])
    ? [
        {
          headerName: 'Entrada',
          field: 'attendance',
          valueGetter: (val) => moment(val.data?.attendance).tz('America/Bogota').format('hh:mm a'),
          cellRenderer: (val) =>
            val.data?.attendance ? (
              <p>{moment(val.data?.attendance).tz('America/Bogota').format('hh:mm a')}</p>
            ) : (
              <Button
                disabled={loading}
                className="btn-grid"
                onClick={() =>
                  checkAttendanceToVolunteer(
                    val.data?.Volunteer.id,
                    service,
                    eventSlugname,
                    val.data?.Volunteer?.Guest,
                    getVolunteersByEvent,
                    set_loading
                  )
                }
                primary
              >
                Entrada
              </Button>
            ),
          width: 150,
        },
      ]
    : []),
  ...(chPerm(['salaExp'])
    ? [
        {
          headerName: 'Refrigerio',
          field: 'snack',
          width: 150,
          valueGetter: (val) => moment(val.data?.snack).tz('America/Bogota').format('hh:mm a'),
          cellRenderer: (val) =>
            val.data?.snack ? (
              <p>{moment(val.data?.snack).tz('America/Bogota').format('hh:mm')}</p>
            ) : (
              <Button
                disabled={loading}
                primary
                className="btn-grid"
                onClick={() =>
                  checkSnackToVolunteer(
                    val.data?.Volunteer.id,
                    service,
                    eventSlugname,
                    val.data?.Volunteer?.Guest,
                    getVolunteersByEvent,
                    set_loading
                  )
                }
              >
                Refrigerio
              </Button>
            ),
        },
      ]
    : []),
];

const ExperienceTeamCheckRow = (props) => {
  const {
    loading,
    service,
    showArea,
    set_loading,
    eventSlugname,
    volunteer = {},
    getVolunteersByEvent,
    showImgs,
    showCedNRole,
  } = props;
  const { Volunteer, snack, attendance } = volunteer;
  const { role, serviceArea, Guest } = Volunteer || {};
  const { name, lastName, identification } = Guest;

  return (
    <tr>
      {showCedNRole && (
        <td>
          <p style={{ margin: '0' }}>{identification}</p>
        </td>
      )}
      {showImgs && (
        <td>
          <Image src={profileImage(Guest)} rounded size="small" />
        </td>
      )}
      <td>
        <p style={{ margin: '0' }}>
          {name}&nbsp;{lastName}
        </p>
      </td>
      {showArea && (
        <td>
          <p style={{ margin: '0' }}>{serviceArea}</p>
        </td>
      )}
      {showCedNRole && (
        <td>
          <p style={{ margin: '0' }}>{role}</p>
        </td>
      )}
      {chPerm(['experienceCoord']) && (
        <td>
          {attendance ? (
            <p>{moment(attendance).tz('America/Bogota').format('hh:mm a')}</p>
          ) : (
            <Button
              disabled={loading}
              onClick={() =>
                checkAttendanceToVolunteer(
                  Volunteer.id,
                  service,
                  eventSlugname,
                  Guest,
                  getVolunteersByEvent,
                  set_loading
                )
              }
              primary
            >
              Entrada
            </Button>
          )}
        </td>
      )}
      {chPerm(['salaExp']) && (
        <td>
          {snack ? (
            <p>{moment(snack).tz('America/Bogota').format('hh:mm')}</p>
          ) : (
            <Button
              disabled={loading}
              primary
              onClick={() =>
                checkSnackToVolunteer(
                  Volunteer.id,
                  service,
                  eventSlugname,
                  Guest,
                  getVolunteersByEvent,
                  set_loading
                )
              }
            >
              Refrigerio
            </Button>
          )}
        </td>
      )}
    </tr>
  );
};

export default ExperienceTeamCheckRow;
