import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import ReactToPrint from 'react-to-print';
import { Button } from 'semantic-ui-react';
import {
  WeddingProjectLeaderCompText,
  WeddingProjectCompText,
  LoveAndRespectLeaderCompText,
  LoveAndRespectCompText,
  RelacionesSanasLeaderCompText,
  RelacionesSanasCompText,
} from '../../../components/groups/compliances/ComplianceTexts';

const ComplianceFilled = (props) => {
  const { inputs, checkCouple, docName, set_showCompliance } = props;

  let document = {
    liderproyectomatrimonio: {
      component: WeddingProjectLeaderCompText,
    },
    proyectomatrimonio: { component: WeddingProjectCompText },
    liderparasiempre: {
      component: LoveAndRespectLeaderCompText,
    },
    parasiempre: { component: LoveAndRespectCompText },
    liderrelacionessanas: {
      component: RelacionesSanasLeaderCompText,
    },
    relacionessanas: { component: RelacionesSanasCompText },
  };

  let errorInputs = {};

  let canvasCoupleSign = { fromData: () => '', off: () => '' };
  let canvasSign = { fromData: () => '', off: () => '' };

  let printable = React.createRef();

  React.useEffect(() => {
    let sign = JSON.parse(inputs.sign);
    canvasSign.fromData([sign]);
    canvasSign.off();
    let coupleSign = JSON.parse(inputs.coupleSign);
    canvasCoupleSign.fromData([coupleSign]);
    canvasCoupleSign.off();
  }, [inputs]);

  const dateOfSign = new Date(inputs['createdAt']);

  const printButton = () => (
    <ReactToPrint
      trigger={() => (
        //eslint-disable-next-line
        <a href="#" onClick={(e) => e.preventDefault()}>
          Imprimir
        </a>
      )}
      content={() => printable.current}
    />
  );
  return (
    <>
      {printButton()}
      <Button
        primary
        compact
        onClick={() => set_showCompliance(null)}
        style={{ display: 'block', margin: '10px 0' }}
      >
        Ir Atrás
      </Button>
      <div className="volunteer-registration" id="show" ref={printable}>
        {document[docName].component()}

        <div className="inner">
          <div className="signs-form">
            <p>
              Fecha de firma: <b>{dateOfSign.getDate()}</b> del mes de{' '}
              <b>{dateOfSign.toLocaleDateString('es-ES', { month: 'long' })}</b> de{' '}
              <b>{dateOfSign.getFullYear()}</b>
            </p>
            <div className="volunteerSign">
              <div className="firstSign">
                <SignatureCanvas
                  penColor="black"
                  ref={(ref) => {
                    canvasSign = ref;
                  }}
                  canvasProps={{
                    width: 300,
                    height: 200,
                    className: `sigCanvas  ${errorInputs['sign'] ? 'error' : ''}`,
                  }}
                />
                <p>Firma él</p>
                <p>
                  <strong>{inputs.fullName}</strong>
                </p>
                <p>{inputs.identification}</p>
              </div>
              {checkCouple && (
                <div className="secondSign">
                  <SignatureCanvas
                    penColor="black"
                    ref={(ref) => {
                      canvasCoupleSign = ref;
                    }}
                    canvasProps={{
                      width: 300,
                      height: 200,
                      className: `sigCanvas  ${errorInputs['sign'] ? 'error' : ''}`,
                    }}
                  />
                  <p>Firma élla</p>
                  <p>
                    <strong>{inputs.coupleFullName}</strong>
                  </p>
                  <p>{inputs.coupleIdentification}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {printButton()}
    </>
  );
};
export default ComplianceFilled;
