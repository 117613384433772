import styled from '@emotion/styled';

export const DashboardCrecerComponent = styled.div`
  background-color: #fafdff;
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;

  .custom-pane {
    background-color: #f6fbff !important;
    padding: 20px;
    border-radius: 20px;
    height: calc(80vh - 100px);
    overflow-y: auto;
  }

  .title {
    color: #191919;
    font-size: 50px;
  }

  .columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    gap: 10px;
  }

  .column {
    position: relative;
    background-color: white;
    flex: 1 1 calc(33.333% - 10px); /* Ajuste para tres columnas en pantallas grandes */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    padding: 10px;
    overflow-y: auto;
    height: auto; /* Cambiado de 450px a auto para adaptarse mejor a contenido variable */
    min-width: 200px; /* Asegura que cada columna tenga un ancho mínimo */
    h3 {
      color: #191919 !important;
      font-weight: 600;
      span {
      }
    }
    &.next-classes {
      .sub {
        display: block;
      }
      .attendance-sign {
        font-size: 14px;
        color: #767676;
        margin: 0 0 10px 0;
      }
    }
  }

  .step-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 18px;
    color: #5f5f5f;
    &.encuentro {
      font-weight: bold;
    }
    &.title {
      font-weight: bold;
      font-size: 21px;
      margin: 0px 0 5px 0;
      &.virtual {
        border-bottom: 1px solid #c1c1c1;
        padding: 0 0 6px 0px;
      }
      span.percentage {
        color: rgb(201, 100, 0);
      }
    }
  }

  .status-indicator {
    width: 20px;
    height: 20px;
    border-radius: 20%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .status-true {
    background-color: #ff7f00;
  }

  .status-false {
    background-color: #fff;
    color: #191919;
  }

  @media (max-width: 768px) {
    .title {
      font-size: 28px;
    }

    .columns {
      flex-direction: column;
    }

    .column {
      flex: 1 1 100%; /* Ocupa el 100% del ancho disponible en móviles */
      margin: 0 0 10px 0; /* Ajusta el margen para móviles */
    }
  }
`;
