import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

const COLORS = ['#242323', '#fc9133', '#8dfce6', '#ff6b6b', '#9b59b6', '#3498db'];

const PieChartComponent = ({
    title,
    total,
    data,
    width = 400,
    height = 400,
    outerRadius = 150,
    dataKey = "value",
    nameKey = null,
    customTooltip = null,
}) => {
    if (!data || data.length === 0) return null;

    return (
        <div className="grafic">
            {title && <h3>{title}{total ? `: ${total}` : ""}</h3>}
            <PieChart width={width} height={height}>
                <Pie
                    data={data}
                    dataKey={dataKey}
                    nameKey={nameKey}
                    cx="50%"
                    cy="50%"
                    outerRadius={outerRadius}
                    fill="#8884d8"
                    label={({ value, percentage }) => `${value} (${percentage}%)`}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip content={customTooltip || null} />
                <Legend />
            </PieChart>
        </div>
    );
};

export default PieChartComponent;
