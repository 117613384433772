import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Layout from '../components/layout/Layout';
import Home from '../containers/Home';
import Registration from '../containers/Registration';
import AllVolunteers from '../containers/volunteer/AllVolunteers';
import Admin from '../containers/Admin';
import BlankLoader from '../components/layout/BlankLoader';
import Error404 from '../containers/Error404';
import CheckIn from '../containers/CheckIn/CheckIn';
import PrintQueueTable from '../containers/PrintTable';
import Login from '../containers/Login';
import PrintPage from '../components/printQueue/printPage';
import Certificate from '../containers/Certificate';
import authService from '../services/auth';
import EventDates from '../containers/admin/EventDates';
import Clase3Spiritual from '../containers/crecer/paso1/clase3Spiritual';
import AllP1C3Answers from '../containers/admin/AllP1C3Answers';
import RedComunidadEmpresa from '../containers/RedComunidadEmpresa';
import ComunidadNetworkAdmin from '../containers/admin/comunidad-network';
import ComunidadNetworkCatalog from '../containers/RedEmpresasCatalog';
import TutorsAllP1C3Answers from '../containers/crecer/paso1/tutors/listAnswersForTutor';
import RepeatFormCrecerP1C3 from '../containers/crecer/paso1/repeatForm';
import AllGuests from '../containers/admin/all-guests';
import AdminGroups from '../containers/admin/groups/groups-admin';
import NewMembersForm from '../containers/church-members/newMember/newMembersForm';
import NewMembersReport from '../containers/admin/NewMembers';
import ServiceReservation from '../containers/church-members/church-service/reservation';
import Cumbre2019CertificatePage from '../containers/certificates/cumbre2019certificatePage';
import GroupReservation from '../components/groups/groupsRegistration';
import LeadersAdminGroup from '../components/groups/leadersView/leadersView';
import GroupCompliance from '../components/groups/groupCompliance';
import AllGroupCompliances from '../containers/admin/groups/Compliances';
import ViewAllServices from '../containers/admin/church-service/listServices';
import GuestLeaders from '../containers/admin/GuestLeaders';
import CrecerAttendance from '../components/crecer/getCrecerAttendance';
import Counseling from '../containers/admin/Counseling';
import CounselingForm from '../containers/church-members/counseling/counselingForm';
import EventCertificate from '../containers/certificates/eventCertificate';
import ParentGeneracionesMatch from '../containers/church-members/church-service/generaciones/parentGeneracionesMatch';
import GeneracionesServicesTable from '../containers/admin/church-service/generaciones/GeneracionesServicesTable';
import { SendCheckoutInfo } from '../containers/church-members/church-service/reservation/checkout/sendInfo';
import TransactionResponse from '../containers/payments/transactionResponse';
import AllNewVolunteers from '../containers/volunteer/AllNewVolunteers';
import ParentsReserve from '../containers/church-members/church-service/generaciones/parents-reserve/parentsReserve';
import AllUsers from '../containers/admin/auth/AllUsers';
import VolunteersLogs from '../containers/experience/volunteersLogs';
import AllConfirmedVolunteers from '../containers/volunteer/AllConfirmedVolunteers';
import ConfirmedVolunteersCheck from '../containers/volunteer/VolunteersChecks';
import NewVolunteersForm from '../containers/volunteer/components/newVolunteersForm';
import { localhostname } from '../components/helpers';
import GuestDashboard from '../components/guestDashboard/GuestDashboard';
import AdminDashboard from '../components/adminDashboard/AdminDashboard';
import ProspectLeadersForm from '../components/groups/prospectLeaders/prospectLeadersForm';
import AllProspectLeaders from '../components/groups/prospectLeaders/allProspectLeaders';
import DiezmosYOfrendas from '../containers/payments/diezmosYOfrendas';
import NewLeadersForm from '../components/groups/newLeaders/newLeadersForm';
import AllNewLeaders from '../components/groups/newLeaders/allNewLeaders';

const App = () => {
  const loc = window.location;
  const protocol = process.env.REACT_APP_PROTOCOL || 'https';
  const hostname = process.env.REACT_APP_HOSTNAME || localhostname;

  const history = createBrowserHistory();

  React.useEffect(() => {
    window.sessionStorage.removeItem('times');
    if (loc.hostname === hostname) {
      if (loc.protocol !== `${protocol}:`) {
        loc.replace(`${protocol}:${loc.href.substring(loc.protocol.length)}`);
      }
    }
    if (loc.hostname !== hostname) {
      loc.replace(`${protocol}://${hostname}${loc.pathname}${loc.search}`);
    }
  }, []);
  function PrivateRoute({ component: Component, ...rest }) {
    if (localStorage.getItem('userToken')) {
      return (
        <Route
          {...rest}
          render={(props) => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      );
    } else {
      return authService.logout();
    }
  }
  function RouteLayout({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Layout {...rest}>
            <Component {...props} />
          </Layout>
        )}
      />
    );
  }
  if (loc.hostname === hostname && loc.protocol === `${protocol}:`) {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/checkin/print" component={PrintPage} />
          <Route exact path="/certificate" component={Certificate} />
          <Route exact path="/payucheckout" component={SendCheckoutInfo} />
          <Route exact path="/logout" component={Login} />
          <RouteLayout exact path="/" component={Home} />
          <RouteLayout exact path="/login" component={Login} />
          <RouteLayout exact path="/respuesta-transaccion" component={TransactionResponse} />
          <RouteLayout exact path="/diezmosyofrendas" component={DiezmosYOfrendas} />

          <RouteLayout exact path="/actualizar-datos" component={CrecerAttendance} />

          {/* Grupos */}
          <RouteLayout exact path="/grupos/acuerdo/:doc" component={GroupCompliance} />
          <RouteLayout exact path="/grupos/registrarse" component={GroupReservation} />
          <RouteLayout exact path="/grupos/lider" component={LeadersAdminGroup} />
          <RouteLayout exact path="/grupos/encuesta/:hexaCode?" component={ProspectLeadersForm} />
          <RouteLayout exact path="/grupos/aspirante" component={NewLeadersForm} />
          <PrivateRoute exact path="/admin/prospect-leaders" component={AllProspectLeaders} />
          <PrivateRoute exact path="/admin/new-leaders" component={AllNewLeaders} />
          <PrivateRoute exact path="/admin/groups/compliances" component={AllGroupCompliances} />
          <PrivateRoute exact path="/admin/groups/:kind" component={AdminGroups} />

          {/* Experiencia */}
          <RouteLayout exact path="/experiencia/voluntarios" component={VolunteersLogs} />
          <PrivateRoute exact path="/voluntarios-servicio" component={ConfirmedVolunteersCheck} />
          <PrivateRoute exact path="/all-volunteers" component={AllVolunteers} />
          <PrivateRoute exact path="/all-confirmed-volunteers" component={AllConfirmedVolunteers} />
          <PrivateRoute exact path="/all-new-volunteers" component={AllNewVolunteers} />
          <RouteLayout exact path="/nuevo-voluntario" component={NewVolunteersForm} />

          {/* Social */}
          <RouteLayout exact path="/bienvenido" component={NewMembersForm} />
          <RouteLayout exact path="/bienvenido-a-casa" component={NewMembersForm} />
          <RouteLayout exact path="/red-empresas" component={RedComunidadEmpresa} />
          <RouteLayout exact path="/catalogo-red-empresas" component={ComunidadNetworkCatalog} />
          <PrivateRoute exact path="/all-network" component={ComunidadNetworkAdmin} />
          <RouteLayout exact path="/consejeria" component={CounselingForm} />
          <PrivateRoute exact path="/counseling" component={Counseling} />

          {/* Crecer */}
          <RouteLayout exact path="/crecer/paso1/encuesta" component={Clase3Spiritual} />
          <RouteLayout exact path="/crecer/tutor" component={TutorsAllP1C3Answers} />
          <RouteLayout exact path="/crecer/repetirencuesta" component={RepeatFormCrecerP1C3} />
          <RouteLayout exact path="/crecer/registrarse/:seminary" component={GroupReservation} />
          <RouteLayout exact path="/crecer/asistencia" component={CrecerAttendance} />
          <PrivateRoute exact path="/event-dates" component={EventDates} />
          <PrivateRoute exact path="/admin/crecer/1/encuestas" component={AllP1C3Answers} />

          {/* Reservaciones */}
          <RouteLayout exact path="/servicio/reservacion" component={ServiceReservation} />
          <PrivateRoute exact path="/checkin/print-queue" component={PrintQueueTable} />
          <PrivateRoute exact path="/checkin" component={CheckIn} />
          <PrivateRoute exact path="/church-services" component={ViewAllServices} />

          {/* Generaciones */}
          <RouteLayout exact path="/servicio/reservacion/generaciones" component={ParentsReserve} />
          <PrivateRoute exact path="/servicio/generaciones" component={ParentGeneracionesMatch} />
          <PrivateRoute exact path="/admin/generaciones" component={GeneracionesServicesTable} />
          <PrivateRoute exact path="/registration" component={Registration} />

          {/* Admin */}
          <PrivateRoute exact path="/admin" component={Admin} />
          <PrivateRoute exact path="/new-members" component={NewMembersReport} />
          <PrivateRoute exact path="/guest-leaders" component={GuestLeaders} />
          <PrivateRoute exact path="/all-guests" component={AllGuests} />
          <PrivateRoute exact path="/all-users" component={AllUsers} />

          {/* Certificados */}
          <PrivateRoute exact path="/certificado/cumbre" component={Cumbre2019CertificatePage} />
          <RouteLayout exact path="/certificado/evento" component={EventCertificate} />

          {/* Guest Dashboard */}
          <RouteLayout exact path="/perfil/*" fullScreen={true} component={GuestDashboard} />

          {/* Admin Dashboard */}
          <PrivateRoute exact path="/tablero/*" fullScreen={true} component={AdminDashboard} />

          {/* Error */}
          <RouteLayout component={Error404} />
        </Switch>
      </Router>
    );
  }
  return <BlankLoader />;
};

export default App;
