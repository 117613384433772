const RelacionesSanasLeaderCompText = () => (
  <>
    <h3 style={{ textAlign: 'center' }}>CONSENTIMIENTO INFORMADO SEMINARIO RELACIONES SANAS</h3>
    <p style={{ textAlign: 'center' }}>Facilitadores y Facilitadores en Entrenamiento</p>
    <p>
      Este proceso tiene como propósito acompañar a cada persona a identificar las heridas más
      profundas del corazón que en algunos casos pueden evidenciarse en comportamientos sexuales y/o
      relacionales incorrectos guiándonos en un proceso de restauración profunda basado en la
      relación con Dios y con los demás llevándolos a comprender el amor del Padre y a descubrir el
      poder que hay en establecer relaciones sanas. De igual manera a recibir capacitación y
      formación para ayudar a otros a vivir en libertad.
    </p>

    <p>Relaciones Sanas consta de 12 clases con la siguiente metodología:</p>
    <ul>
      <li>Primera parte: seminario/taller de 45 minutos</li>
      <li>Segunda parte: grupos de transparencia 45 minutos.</li>
    </ul>

    <h3>GRUPOS DE TRANSPARENCIA:</h3>
    <p>
      Son grupos de máximo 7 personas, 1 facilitador y 1 facilitador de apoyo. Los espacios son
      creados con la finalidad de propiciar un ambiente seguro donde cada participante puede exponer
      los lugares más profundos de su corazón y aprender a ser vulnerable con otras personas en
      cuanto a sus quebrantos, rendir cuentas sobre avances de su proceso y también poder tener una
      retroalimentación de cada tema tratado en el taller. Podríamos decir que son espacios de
      transparencia donde con amor y cuidado nos alentamos los unos a los otros a permanecer en
      libertad.
    </p>

    <p>
      Es sumamente importante comprender que si por medio de las relaciones fuimos heridos, por
      medio de las relaciones seremos sanados.
    </p>

    <h3>Facilitadores y Facilitadores en Entrenamiento</h3>
    <p>
      Estamos llamados a una labor que creemos es parte del deseo de Dios para esta generación: ser
      instrumentos de restauración. Él desea ministrar sanidad por medio de los brazos del cuerpo de
      Cristo a todas las personas y justamente es lo que deseamos hacer por medio de los grupos de
      transparencia, ser sensibles al dolor y la vergüenza de nuestros hermanos y “llorar con los
      que lloran”. Para esto debemos tener corazones llenos de misericordia y amor.
    </p>

    <p>
      Sabemos que la experiencia como facilitador y facilitador en entrenamiento en todo este
      proceso será vivificante; eres el promotor que las personas puedan experimentar 1 Juan 1:7
      “pero si andamos en luz como él está en luz, tenemos comunión unos con otros y la sangre de
      Jesucristo su Hijo nos limpia de todo pecado”. Esto también nos hace conscientes de la
      responsabilidad constante de mantenernos en la luz y ser vulnerables con nuestras vidas y
      nuestros procesos dentro de los grupos de transparencia.
    </p>

    <h3>Pautas y Límites</h3>
    <p>
      De igual forma, vamos a establecer unas pautas y límites dentro de los grupos de transparencia
      que nos ayudarán a cumplir el propósito. Es importante tener claros los comportamientos
      pecaminosos que serán expuestos en todo este proceso, esto es muy importante ya que muchos de
      los participantes tienen confusión en cuanto a que de su comportamiento es incorrecto o no.
    </p>

    <h3>Definiciones:</h3>
    <p>
      <strong>(a) Adicción Sexual:</strong> Entiéndase como adicción sexual a un patrón continuo y
      descontrolado de buscar alivio sexual. Esto incluye: fantasía y masturbación compulsiva,
      contactos interpersonales (sexo cibernético, prostitución, visitas a lugares donde se realizan
      desnudos), uso de la pornografía (Internet, videos, revistas) y sexo telefónico. La adicción
      por su propia naturaleza es habitual. Nos referimos a la dependencia continua de estas
      conductas como si fuera una droga.
    </p>

    <p>
      <strong>(b) Comportamiento sexual incorrecto:</strong> Entiéndase como comportamiento sexual
      incorrecto a un evento sexual esporádico como fantasía y masturbación, contactos
      interpersonales (sexo cibernético, prostitución, visitas a lugares donde se realizan
      desnudos), uso de la pornografía (Internet, videos, revistas) y sexo telefónico, toda forma de
      contacto genital con otra persona (contacto genital incluye tocar los senos, los glúteos y los
      genitales, ya sea con ropa o sin ropa).
    </p>

    <p>
      <strong>(c) Relaciones dependientes:</strong> Preocupación o necesidad obsesiva por una
      relación de la cual depende la valía de la persona.
    </p>

    <h2>AUTORIZACIÓN PARA FOTOS Y VIDEOS Y HABEAS DATA</h2>
    <p>
      Confiero mi autorización expresa y voluntaria a la IGLESIA COMUNIDAD CRISTIANA DE FE Y VIENTO
      FRESCO para que se tomen registros fotográficos y se realicen videos para ser publicados en
      Internet o medios de comunicación y adicionalmente autorizo utilizar mis datos personales con
      el fin de informar acerca de eventos y servicios de forma segura. En cualquier momento puedo
      solicitar su corrección, actualización o eliminación según términos establecidos en la ley
      Habeas Data 1581 de 2012.
    </p>

    <p>
      He leído cuidadosamente y con la debida atención el contenido del presente documento, todas
      las manifestaciones son verídicas y cualquier omisión o responsabilidad derivada de los mismos
      es única y exclusivamente de quien lo suscribe. Así mismo, entiendo y acepto totalmente su
      contenido y asumo los riesgos que se desprenden de mi participación en el seminario. Además
      acepto las políticas, principios y valores morales y éticos bíblicos que rigen la Iglesia
      Comunidad Cristiana de Fe y Viento Fresco, aclarando que al ir al seminario es porque estoy
      sujeto a estos.
    </p>

    <p>
      Si desea más información, puede dar click en{' '}
      <a
        target="_blank"
        href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=49981"
        rel="noreferrer"
      >
        TÉRMINOS Y CONDICIONES
      </a>{' '}
      para ampliar la información.
    </p>
  </>
);
const RelacionesSanasCompText = () => (
  <>
    <h3 style={{ textAlign: 'center' }}>CONSENTIMIENTO INFORMADO SEMINARIO RELACIONES SANAS</h3>
    <p style={{ textAlign: 'center' }}>ASISTENTE</p>
    <p>
      Este proceso tiene como propósito acompañar a cada persona a identificar las heridas más
      profundas del corazón que en algunos casos pueden evidenciarse en comportamientos sexuales y/o
      relacionales incorrectos, guiándonos en un proceso de restauración profunda basado en la
      relación con Dios y con los demás, llevándolos a comprender el amor del Padre y a descubrir el
      poder que hay en establecer relaciones sanas. De igual manera, a recibir capacitación y
      formación para ayudar a otros a vivir en libertad.
    </p>

    <p>
      <strong>Relaciones Sanas</strong> consta de 12 clases con la siguiente metodología: primera
      parte: seminario/taller de 45 minutos, segunda parte: grupos de transparencia de 45 minutos.
    </p>

    <h2>Grupos de Transparencia</h2>

    <p>
      Son grupos de máximo 7 personas, 1 facilitador y 1 facilitador de apoyo. Los espacios son
      creados con la finalidad de propiciar un ambiente seguro donde cada participante puede exponer
      los lugares más profundos de su corazón y aprender a ser vulnerable con otras personas en
      cuanto a sus quebrantos, rendir cuentas sobre avances de su proceso y también poder tener una
      retroalimentación de cada tema tratado en el taller. Podríamos decir que son espacios de
      transparencia donde con amor y cuidado nos alentamos los unos a los otros a permanecer en
      libertad.
    </p>

    <p>
      Es sumamente importante comprender que si por medio de las relaciones fuimos heridos, por
      medio de las relaciones seremos sanados.
    </p>

    <h3>Consideraciones para los Participantes</h3>

    <ol>
      <li>
        Es importante que cada participante tome el proceso desde el principio hasta el fin del
        mismo. Creemos que los procesos completos nos ayudan a formarnos en disciplina y madurez,
        esto será clave para lograr el propósito Relaciones Sanas.
      </li>
      <li>
        Relaciones Sanas no garantiza la libertad de ningún quebranto sexual o relacional ya que
        esto es obra del Espíritu Santo y responsabilidad de cada persona. Relaciones Sanas solo
        propicia un espacio de formación, vulnerabilidad, respeto y seguridad para iniciar un
        proceso de libertad; los cambios en la vida de cada persona dependerán de sus acciones.
      </li>
      <li>
        Los hombres y mujeres inscritos hacen un acuerdo de confidencialidad donde se comprometen a:
        <ul>
          <li>
            Ser totalmente confidentes de todas las conversaciones que se hagan en los grupos de
            transparencia. Está absolutamente prohibida la divulgación de información escuchada en
            los grupos de transparencia que ponga en riesgo la vulnerabilidad de otro participante.
          </li>
          <li>
            Todo lo hablado dentro de los grupos de transparencia es de completa confidencialidad a
            menos que lo que se diga viole los derechos humanos de otras personas (abuso sexual,
            intento de homicidio, entre otros), lo cual dejará nulo el acuerdo y se hará el debido
            procedimiento con las autoridades competentes.
          </li>
        </ul>
      </li>
    </ol>

    <p>
      <strong>NOTA:</strong> Al firmar el siguiente documento, estoy diciendo que estoy de acuerdo
      con todas las cosas allí expresadas.
    </p>

    <h2>Autorización para Fotos y Videos y Habeas Data</h2>

    <p>
      Confiero mi autorización expresa y voluntaria a la IGLESIA COMUNIDAD CRISTIANA DE FE Y VIENTO
      FRESCO para que se tomen registros fotográficos y se realicen videos para ser publicados en
      Internet o medios de comunicación, y adicionalmente autorizo utilizar mis datos personales con
      el fin de informar acerca de eventos y servicios de forma segura. En cualquier momento puedo
      solicitar su corrección, actualización o eliminación según términos establecidos en la ley
      Habeas data 1581 de 2012.
    </p>

    <p>
      He leído cuidadosamente y con la debida atención el contenido del presente documento, todas
      las manifestaciones son verídicas y cualquier omisión o responsabilidad derivada de los mismos
      es única y exclusivamente de quien lo suscribe; así mismo, entiendo y acepto totalmente su
      contenido y asumo los riesgos que se desprenden de mi participación en el seminario. Además,
      acepto las políticas, principios y valores morales y éticos bíblicos que rigen la Iglesia
      Comunidad Cristiana de Fe y Viento Fresco, aclarando que al ir al seminario es porque estoy
      sujeto a estos. Si desea más información, puede dar click en{' '}
      <a
        target="_blank"
        href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=49981"
        rel="noreferrer"
      >
        TÉRMINOS Y CONDICIONES
      </a>{' '}
      para ampliar la información.
    </p>
  </>
);
const LoveAndRespectCompText = () => (
  <>
    <h3 style={{ textAlign: 'center' }}>CONSENTIMIENTO INFORMADO SEMINARIO Para Siempre</h3>
    <p style={{ textAlign: 'center' }}>PARTICIPANTES</p>
    <p>
      El Seminario Para Siempre tiene como propósito impartir los principios que la palabra de Dios
      tiene para sanar, restaurar, fortalecer y crecer las relaciones de pareja.
    </p>
    <p>
      El Seminario Para Siempre consta de 12 clases con la siguiente metodología: oración, compartir
      el taller trabajado en pareja, exposición magistral por parte de la pareja facilitadora,
      inicio de taller y oración. Duración máxima de 1 hora y media.
    </p>
    <p>
      Como participantes, será una experiencia transformadora a medida que vayan aprendiendo a ser
      transparentes y teniendo cuidado el uno del otro.
    </p>

    <h2>Consideraciones importantes:</h2>

    <p>
      <strong>Permanencia:</strong> Es importante que cada pareja participante tome el proceso de
      principio a fin. Los procesos completos nos ayudan a formarnos en disciplina y madurez, esto
      será clave para lograr el propósito de este Seminario. La pareja se compromete a separar cada
      semana un espacio para elaborar y compartir el taller con su pareja. Al finalizar el seminario
      se otorgará un certificado, pero para obtenerlo deben tener completas todas las clases.
    </p>

    <p>
      <strong>Responsabilidad Personal:</strong> El Seminario Para Siempre no garantiza la
      restauración y sanidad en la pareja, ya que esto es obra del Espíritu Santo y responsabilidad
      de cada persona. El Seminario propicia un espacio de formación, vulnerabilidad, respeto y
      seguridad para iniciar el proceso de sanidad y restauración, pero los cambios en la vida de
      cada persona dependerán de sus acciones.
    </p>

    <p>
      <strong>Confidencialidad:</strong> Todos los participantes deben ser totalmente confidentes de
      todas las conversaciones que se realicen durante el seminario. Está absolutamente prohibida la
      divulgación de información escuchada en los seminarios que pongan en riesgo la vulnerabilidad
      de otro participante. La única excepción es si se menciona algo que viole los derechos humanos
      de otras personas (abuso sexual, intento de homicidio y otros), lo cual dejará nulo el acuerdo
      y se hará el debido procedimiento con las autoridades competentes.
    </p>

    <p>
      <strong>Honestidad:</strong> Las parejas asistentes se comprometen a utilizar las reuniones
      exclusivamente para compartir los temas relacionados con el Seminario. Nunca usarán las
      reuniones para compartir sobre sus negocios particulares.
    </p>

    <p>Como pareja hemos entendido y decidimos sujetarnos a estas consideraciones.</p>

    <h2>Autorización para Fotos y Videos y Habeas Data</h2>

    <p>
      Confiero mi autorización expresa y voluntaria a la IGLESIA COMUNIDAD CRISTIANA DE FE Y VIENTO
      FRESCO para que se tomen registros fotográficos y se realicen videos para ser publicados en
      Internet o medios de comunicación, y adicionalmente autorizo utilizar mis datos personales con
      el fin de informar acerca de eventos y servicios de forma segura. En cualquier momento puedo
      solicitar su corrección, actualización o eliminación según términos establecidos en la ley
      Habeas Data 1581 de 2012.
    </p>

    <p>
      He leído cuidadosamente y con la debida atención el contenido del presente documento, todas
      las manifestaciones son verídicas y cualquier omisión o responsabilidad derivada de los mismos
      es única y exclusivamente de quien lo suscribe. Así mismo entiendo y acepto totalmente su
      contenido y asumo los riesgos que se desprenden de mi participación en el seminario. Además,
      acepto las políticas, principios y valores morales y éticos bíblicos que rigen la Iglesia
      Comunidad Cristiana de Fe y Viento Fresco, aclarando que al ir al seminario es porque estoy
      sujeto a estos. Si desea más información, puede dar click en Términos y Condiciones para
      ampliar la información.
    </p>
  </>
);
const LoveAndRespectLeaderCompText = () => (
  <>
    <h3 style={{ textAlign: 'center' }}>CONSENTIMIENTO INFORMADO SEMINARIO AMOR Y RESPETO</h3>
    <p style={{ textAlign: 'center' }}>Facilitadores y Facilitadores en Entrenamiento</p>
    <p>
      El Seminario Para Siempre tiene como propósito impartir los principios que la palabra de Dios
      tiene para sanar, restaurar, fortalecer y crecer las relaciones de pareja.
    </p>
    <p>
      El Seminario Para Siempre consta de 12 clases con la siguiente metodología: oración, compartir
      el taller trabajado en pareja, exposición magistral por parte de la pareja líder, inicio de
      taller, oración. Duración máxima de 1 hora y media.
    </p>
    <p>
      El límite de parejas lo define la pareja facilitadora y su pareja en entrenamiento. Cada
      pareja sabe cuántas parejas puede acompañar de manera excelente. De tal manera que se sientan
      acompañadas y puedan estar disponibles para apoyarles.
    </p>
    <p>
      Como parejas Facilitadoras y parejas en entrenamiento somos conscientes que somos instrumentos
      de restauración. Reconocemos que no somos perfectos y que Dios sigue perfeccionando su obra en
      nosotros. Pero aun en medio de la imperfección queremos compartir las poderosas herramientas
      que tiene la palabra de Dios para traer sanidad, libertad, restauración y esperanza.
    </p>
    <p>
      Las parejas facilitadoras deseamos estar en una comunicación permanente con Dios por medio de
      la lectura y meditación de la palabra escrita y somos conscientes que la oración tiene un
      poder sobrenatural para potencializar las relaciones de pareja a un nivel mayor.
    </p>
    <p>
      Las parejas facilitadoras son parejas que tienen una compasión y un amor para ayudar a otras
      parejas y están dispuestas de manera libre y voluntaria a mostrarles una ruta de salida a las
      parejas asistentes.
    </p>
    <p>
      Las parejas facilitadoras han experimentado en su propia relación matrimonial la revelación
      paulatina de los principios compartidos y han aprendido a depender de Dios en cada aspecto de
      su vida, teniendo presente que cada uno tiene una parte importante para aportar en la
      solución.
    </p>

    <h3>Consideraciones importantes:</h3>
    <ol>
      <li>
        <strong>Confidencialidad.</strong> La pareja facilitadora y/o pareja en entrenamiento
        guardará la información compartida en el grupo y durante las sesiones particulares en
        completa confidencialidad a menos que la información recibida implique un tema que deba ser
        tratado con mayor profundidad, por ejemplo, en caso de que se identifique un abuso sobre un
        menor de edad o un adulto mayor, o si la integridad física de alguien está en riesgo.
      </li>
      <li>
        <strong>Transparencia y Vulnerabilidad.</strong> Se espera de la pareja facilitadora y/o
        pareja en entrenamiento que busque apoyo inmediato con su pareja líder en caso de estar
        viviendo circunstancias difíciles que requieran de acompañamiento.
      </li>
      <li>
        <strong>Honestidad.</strong> Las parejas facilitadoras y/o parejas en entrenamiento se
        comprometen a utilizar las reuniones exclusivamente para compartir los temas relacionados
        con el Seminario y orientar y apoyar a las parejas asistentes. Nunca usarán las reuniones
        para compartir sobre sus negocios particulares ni consejerías. Se recomienda orientación
        bíblica directamente con su líder de grupo de discipulado.
      </li>
      <li>
        <strong>Diligencia.</strong> La pareja facilitadora y/o en entrenamiento se compromete a
        gestionar el consentimiento informado de cada pareja asistente al Seminario de Amor y
        Respeto que atienda.
      </li>
    </ol>

    <p>
      Como pareja facilitadora y/o pareja en entrenamiento hemos entendido y decidimos sujetarnos a
      estas consideraciones.
    </p>

    <h3>AUTORIZACIÓN PARA FOTOS Y VIDEOS Y HABEAS DATA</h3>
    <p>
      Confiero mi autorización expresa y voluntaria a la IGLESIA COMUNIDAD CRISTIANA DE FE Y VIENTO
      FRESCO para que se tomen registros fotográficos y se realicen videos para ser publicados en
      Internet o medios de comunicación y adicionalmente autorizo utilizar mis datos personales con
      el fin de informar acerca de eventos y servicios de forma segura. En cualquier momento puedo
      solicitar su corrección, actualización o eliminación según términos establecidos en la ley
      Habeas Data 1581 de 2012.
    </p>
    <p>
      He leído cuidadosamente y con la debida atención el contenido del presente documento, todas
      las manifestaciones son verídicas y cualquier omisión o responsabilidad derivada de los mismos
      es única y exclusivamente de quien lo suscribe, así mismo entiendo y acepto totalmente su
      contenido y asumo los riesgos que se desprenden de mi participación en el seminario. Además,
      acepto las políticas, principios y valores morales y éticos bíblicos que rigen la Iglesia
      Comunidad Cristiana de Fe y Viento Fresco, aclarando que al ir al seminario es porque estoy
      sujeto a estos. Si desea más información puede dar click en TÉRMINOS Y CONDICIONES para
      ampliar la información.
    </p>

    <h2>AR Participante</h2>
    <p>
      El Seminario Para Siempre tiene como propósito impartir los principios que la palabra de Dios
      tiene para sanar, restaurar, fortalecer y crecer las relaciones de pareja.
    </p>
    <p>
      El Seminario Para Siempre consta de 12 clases con la siguiente metodología: oración, compartir
      el taller trabajado en pareja, exposición magistral por parte de la pareja facilitadora,
      inicio de taller, oración. Duración máxima de 1 hora y media.
    </p>
    <p>
      Como participantes será una experiencia transformadora a medida que vayan aprendiendo a ser
      transparentes teniendo cuidado el uno del otro.
    </p>

    <h3>Consideraciones importantes:</h3>
    <ol>
      <li>
        <strong>Permanencia.</strong> Es importante que cada pareja participante tome el proceso de
        principio a fin. Los procesos completos nos ayudan a formarnos en disciplina y madurez, esto
        será clave para lograr el propósito de este Seminario. La pareja se compromete a separar
        cada semana un espacio para elaborar y compartir el taller con su pareja. Al finalizar el
        seminario se otorgará un certificado, pero para obtenerlo deben de tener completas todas las
        clases.
      </li>
      <li>
        <strong>Responsabilidad Personal.</strong> El Seminario Para Siempre no garantiza la
        restauración y sanidad en la pareja ya que esto es obra del Espíritu Santo y responsabilidad
        de cada persona. El Seminario propicia un espacio de formación, vulnerabilidad, respeto y
        seguridad para iniciar el proceso de sanidad y restauración, pero los cambios en la vida de
        cada persona dependerán de sus acciones.
      </li>
      <li>
        <strong>Confidencialidad.</strong> Todos los participantes deben ser totalmente confidentes
        de todas las conversaciones que se realicen durante el seminario. Está absolutamente
        prohibida la divulgación de información escuchada en los seminarios que pongan en riesgo la
        vulnerabilidad de otro participante. La única excepción es si se menciona algo que viole los
        derechos humanos de otras personas (abuso sexual, intento de homicidio, y otros) lo cual
        dejará nulo el acuerdo y se hará el debido procedimiento con las autoridades competentes.
      </li>
      <li>
        <strong>Honestidad.</strong> Las parejas asistentes se comprometen a utilizar las reuniones
        exclusivamente para compartir los temas relacionados con el Seminario. Nunca usarán las
        reuniones para compartir sobre sus negocios particulares.
      </li>
    </ol>

    <p>Como pareja hemos entendido y decidimos sujetarnos a estas consideraciones.</p>

    <h3>AUTORIZACIÓN PARA FOTOS Y VIDEOS Y HABEAS DATA</h3>
    <p>
      Confiero mi autorización expresa y voluntaria a la IGLESIA COMUNIDAD CRISTIANA DE FE Y VIENTO
      FRESCO para que se tomen registros fotográficos y se realicen videos para ser publicados en
      Internet o medios de comunicación y adicionalmente autorizo utilizar mis datos personales con
      el fin de informar acerca de eventos y servicios de forma segura. En cualquier momento puedo
      solicitar su corrección, actualización o eliminación según términos establecidos en la ley
      Habeas Data 1581 de 2012.
    </p>
    <p>
      He leído cuidadosamente y con la debida atención el contenido del presente documento, todas
      las manifestaciones son verídicas y cualquier omisión o responsabilidad derivada de los mismos
      es única y exclusivamente de quien lo suscribe, así mismo entiendo y acepto totalmente su
      contenido y asumo los riesgos que se desprenden de mi participación en el seminario. Además,
      acepto las políticas, principios y valores morales y éticos bíblicos que rigen la Iglesia
      Comunidad Cristiana de Fe y Viento Fresco, aclarando que al ir al seminario es porque estoy
      sujeto a estos. Si desea más información puede dar click en{' '}
      <a
        target="_blank"
        href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=49981"
        rel="noreferrer"
      >
        TÉRMINOS Y CONDICIONES
      </a>{' '}
      para ampliar la información.
    </p>
  </>
);
const WeddingProjectLeaderCompText = () => (
  <>
    <h3 style={{ textAlign: 'center' }}>CONSENTIMIENTO INFORMADO SEMINARIO PROYECTO MATRIMONIO</h3>
    <p style={{ textAlign: 'center' }}>Facilitadores y Facilitadores en Entrenamiento</p>
    <p>
      En todos los casos y eventos se deberá firmar este documento de exoneración de responsabilidad
      civil legal y administrativa como requisito indispensable para participar en el encuentro,
      campamento o evento. En caso de que el participante sea un menor de edad o una persona con
      capacidades especiales, siempre debe contar con la respectiva autorización escrita de sus
      padres y/o tutor legal.
    </p>

    <p>
      Personería Jurídica Especial No. 2675 del 18 de Diciembre 1997 del Ministerio del Interior
    </p>

    <h2>El seminario de PROYECTO MATRIMONIO</h2>
    <p>
      Tiene como propósito hacer un viaje al corazón para explorar áreas que necesitan ser
      ajustadas, sanadas y limpiadas; antes de tomar la decisión de entrar al matrimonio.{' '}
      <span class="bold">Proyecto Matrimonio</span> consta de 12 clases con la siguiente
      metodología: oración / exposición magistral / compartir el taller trabajado en pareja durante
      la semana / inicio de taller / oración. Duración máxima 1 hora y media. El límite de parejas
      es entre 8 y 10 parejas por grupo y una pareja de apoyo si es el caso.
    </p>

    <p>
      Como pareja facilitadora y parejas de apoyo, somos conscientes que somos instrumentos de
      restauración. Reconocemos que no somos perfectos y que Dios sigue perfeccionando su obra en
      nosotros. Pero aun en medio de la imperfección, queremos compartir las poderosas herramientas
      que tiene la palabra de Dios para traer sanidad, libertad, restauración y esperanza.
    </p>

    <p>
      Las parejas facilitadoras deseamos estar en una comunicación permanente con Dios por medio de
      la lectura y meditación de la palabra escrita y somos conscientes que la oración tiene un
      poder sobrenatural para potencializar las relaciones de pareja a un nivel mayor. Las parejas
      facilitadoras son parejas que tienen una compasión y un amor para ayudar a otras parejas y
      están dispuestas de manera libre y voluntaria a mostrarles una ruta de salida a las parejas
      participantes.
    </p>

    <p>
      Las parejas líderes han experimentado en su propia relación matrimonial la revelación
      paulatina de los principios compartidos y han aprendido a depender de Dios en cada aspecto de
      su vida teniendo presente que cada uno tiene una parte importante para aportar en la solución.
    </p>

    <h3>Consideraciones importantes:</h3>
    <ul>
      <li>
        <strong>Confidencialidad.</strong> La pareja facilitadora y/o pareja de apoyo guardará la
        información compartida en el grupo y durante las sesiones particulares en completa
        confidencialidad, a menos que la información recibida implique un tema que deba ser tratado
        con mayor profundidad, por ejemplo, en caso de que se identifique un abuso sobre un menor de
        edad o un adulto mayor o si la integridad física de alguna está en riesgo. La pareja
        facilitadora y/o pareja de apoyo se compromete a seguir la ruta de atención definida por la
        iglesia y no se apresura a dar soluciones que contemple el divorcio hasta no profundizar
        bien el caso.
      </li>
      <li>
        <strong>Transparencia y Vulnerabilidad.</strong> Se espera de la pareja facilitadora que
        busque apoyo inmediato con su pareja en caso de estar viviendo circunstancias difíciles y
        que requieran de acompañamiento.
      </li>
      <li>
        <strong>Honestidad.</strong> Las parejas facilitadoras y/o parejas de apoyo se comprometen a
        utilizar las reuniones exclusivamente para compartir los temas relacionados con el Seminario
        y orientar y apoyar a las parejas participantes. Nunca usarán las reuniones para compartir
        sobre sus negocios particulares.
      </li>
      <li>
        <strong>Generosidad.</strong> Las parejas facilitadoras y/o parejas de apoyo se comprometen
        a no tomar ofrendas a nivel personal.
      </li>
      <li>
        <strong>Diligencia.</strong> La pareja facilitadora y/o pareja de apoyo se compromete a
        gestionar el consentimiento informado de cada pareja participante al Seminario de Proyecto
        Matrimonio que atienda.
      </li>
    </ul>

    <p>
      Como pareja facilitadora y/o pareja de apoyo hemos entendido y decidimos sujetarnos a estas
      consideraciones.
    </p>

    <p class="italic">
      NOTA: Al firmar este documento estamos diciendo que estamos de acuerdo con todas las cosas
      allí expresadas.
    </p>
  </>
);
const WeddingProjectCompText = () => (
  <>
    <h3 style={{ textAlign: 'center' }}>CONSENTIMIENTO INFORMADO SEMINARIO PROYECTO MATRIMONIO</h3>
    <p style={{ textAlign: 'center' }}>PARTICIPANTES</p>
    <div class="section">
      <h2>Propósito del Seminario:</h2>
      <p>
        El seminario de <strong>PROYECTO MATRIMONIO</strong> tiene como propósito hacer un viaje al
        corazón para explorar áreas que necesitan ser ajustadas, sanadas y limpiadas; antes de tomar
        la decisión de entrar al matrimonio.
      </p>
      <p>
        <strong>Proyecto Matrimonio</strong> consta de 12 clases con la siguiente metodología:
        oración, exposición magistral, compartir el taller trabajado en pareja durante la semana,
        inicio de taller, oración. Duración máxima 1 hora y media.
      </p>
      <p>
        Como participantes, será una experiencia transformadora a medida que vayan aprendiendo a ser
        transparentes teniendo cuidado el uno del otro.
      </p>
      <p>
        Es sumamente importante comprender que si por medio de las relaciones fuimos heridos, por
        medio de las relaciones seremos sanados.
      </p>
    </div>

    <div class="section">
      <h2>Consideraciones importantes:</h2>
      <p>
        <strong>Permanencia.</strong> Se recomienda que cada pareja participante tome el proceso de
        principio a fin. Los procesos completos nos ayudan a formarnos en disciplina y madurez, esto
        será clave para lograr el propósito de este Seminario. La pareja se compromete a separar
        cada semana un espacio para elaborar y compartir el taller con su pareja.
      </p>
      <p>
        <strong>Responsabilidad Personal.</strong> El Seminario de Proyecto Matrimonio no garantiza
        la restauración y sanidad en la pareja ya que esto es obra del Espíritu Santo y
        responsabilidad de cada persona, tampoco garantiza un matrimonio. El Seminario propicia un
        espacio de formación, vulnerabilidad, respeto y seguridad para iniciar el proceso de
        sanidad, restauración y preparación, pero los cambios en la vida de cada persona dependerán
        de sus acciones.
      </p>
      <p>
        <strong>Confidencialidad.</strong> Todos los participantes deben ser totalmente
        confidenciales de todas las conversaciones que se realicen durante el seminario. Está
        absolutamente prohibida la divulgación de información escuchada en los seminarios que pongan
        en riesgo la vulnerabilidad de otro participante. La única excepción es si se menciona algo
        que viole los derechos humanos de otras personas (abuso sexual, intento de homicidio y
        otros), lo cual dejará nulo el acuerdo y se hará el debido procedimiento con las autoridades
        competentes.
      </p>
      <p>
        <strong>Honestidad.</strong> Las parejas asistentes se comprometen a utilizar las reuniones
        exclusivamente para compartir los temas relacionados con el Seminario. Nunca usarán las
        reuniones para compartir sobre sus negocios particulares.
      </p>
      <p>Como pareja asistente hemos entendido y decidimos sujetarnos a estas consideraciones.</p>
    </div>

    <div class="section">
      <h2>Autorización para fotos y videos y Habeas Data:</h2>
      <p>
        Confiero mi autorización expresa y voluntaria a la IGLESIA COMUNIDAD CRISTIANA DE FE Y
        VIENTO FRESCO para que se tomen registros fotográficos y se realicen videos para ser
        publicados en Internet o medios de comunicación, y adicionalmente autorizo utilizar mis
        datos personales con el fin de informar acerca de eventos y servicios de forma segura. En
        cualquier momento puedo solicitar su corrección, actualización o eliminación según términos
        establecidos en la ley Habeas data 1581 de 2012.
      </p>
      <p>
        He leído cuidadosamente y con la debida atención el contenido del presente documento, todas
        las manifestaciones son verídicas y cualquier omisión o responsabilidad derivada de los
        mismos es única y exclusivamente de quien lo suscribe. Así mismo, entiendo y acepto
        totalmente su contenido y asumo los riesgos que se desprenden de mi participación en el
        seminario. Además, acepto las políticas, principios y valores morales y éticos bíblicos que
        rigen la Iglesia Comunidad Cristiana de Fe y Viento Fresco, aclarando que al ir al seminario
        es porque estoy sujeto a estos. Si desea más información puede dar click en{' '}
        <a
          target="_blank"
          href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=49981"
          rel="noreferrer"
        >
          TÉRMINOS Y CONDICIONES
        </a>{' '}
        para ampliar la información.
      </p>
    </div>
  </>
);
export {
  RelacionesSanasLeaderCompText,
  RelacionesSanasCompText,
  LoveAndRespectCompText,
  LoveAndRespectLeaderCompText,
  WeddingProjectLeaderCompText,
  WeddingProjectCompText,
};
